import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useMediaQuery } from "@mui/material";

const PricePolicy = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Header />
      <div
        style={{
          paddingLeft: isMobile ? "30PX" : "150px",
          paddingRight: isMobile ? "30PX" : "150px",
          font: "Inter",
          textAlign: "justify",
          fontSize: "18px",
        }}
      >
        <h1 style={{ font: "Inter", fontSize: "28px" }}>
        Price Policy
        </h1>

        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          At EduCampus Technologies, we strive to provide high-quality IT training at competitive prices. Our pricing policy is designed to ensure transparency and fairness for all our students. Below are the key aspects of our pricing policy:
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          1. Course Fees
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
         Course Registration<br></br>
The course fees are clearly stated on our website for each course offered.
Fees vary depending on the type, duration, and level of the course.
All fees must be paid in full or through an approved payment plan before the commencement of the course.
Discounts and Promotions
We occasionally offer discounts and promotional rates. These will be clearly advertised on our website and through our communication channels.
Discounts cannot be combined unless explicitly stated.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          2. Payment Methods
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Accepted Payment Methods<br/>
We accept various payment methods, including credit/debit cards, bank transfers, and online payment platforms.
All transactions are processed through secure and reliable payment gateways to ensure the safety of your financial information.
Payment Plans
For select courses, we offer flexible payment plans to spread the cost over a period.
Payment plans are subject to approval and may require a deposit at the time of registration.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          3. Additional Costs
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Course Materials<br/>
Some courses may require additional materials, such as textbooks or software, which are not included in the course fee. These additional costs will be specified in the course description.
Examination Fees
Courses that include certification exams may have separate examination fees. These fees will be clearly stated and are payable before the examination date.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          4. Changes to Pricing
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Edu Campus Technologies reserves the right to change course fees, discounts, and other pricing details at any time. However, once you have registered and paid for a course, your fees will not be subject to any changes.
          Any changes to our pricing policy will be updated on our website and communicated to registered students as necessary.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          5. Contact Us
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          If you have any questions about our pricing policy, please contact us:
        </p>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Email: <span style={{textDecoration:"underline"}}>sachin.s@educampustech.in</span>
        </p>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Phone: <span >9940688891</span>
        </p>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Address:  <span>#33/13, Second Floor,

Kaliamman Koil Street,

SBI/Canara Bank Building,

Chinmaya Nagar, Chennai–600092,

Tamil Nadu, India.</span>
        </p>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Thank you for choosing EduCampus Technologies for your IT training needs. We look forward to supporting you on your educational journey.
        </p>

      </div>
      <Footer />
    </div>
  );
};

export default PricePolicy;
