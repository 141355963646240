import React, { useEffect, useState } from "react";
import "./HomePage.css";
import Logo from "../Images/Frame 115 (1).png";
import Button from "@mui/material/Button";
import { ROUTINGDATA } from "./RoudingData";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const drawerWidth = 220;

const Header = () => {
  const [state, setState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("userId");
    if (storedData) {
      setData(storedData);
    }
  }, []);

  const handleLoginClick = () => {
    navigate(ROUTINGDATA.LOGIN);
    window.scrollTo(0, 0);
  };

  const handleSignupClick = () => {
    navigate(ROUTINGDATA.SIGNUP);
    window.scrollTo(0, 0);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const handleProfileClick = () => {
    handleClickOpen();
  };

  const handleMouseEnter = () => {
    setShowProfile(true);
  };

  const handleMouseLeave = () => {
    setShowProfile(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setOpen(false);
    setData("");
    localStorage.removeItem("userId");
    navigate("/");
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 5),
    ...theme.mixins.toolbar,
    justifyContent: "space-around",
  }));
  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div>
      <header className="header">
        <div className="header-left">
          <img
            src={Logo}
            alt="Logo"
            onClick={() => {
              navigate(ROUTINGDATA.HOME);
              window.scrollTo(0, 0);
            }}
            className="MainLogo"
          />
        </div>
        <div className="menuIcon">
          <MenuIcon onClick={toggleDrawer(true)} />
        </div>

        <div className="header-right">
          <nav className="nav">
            <ul>
              <li>
                <div className="dropdown">
                  <button
                    className={`dropbtn ${
                      isActive(ROUTINGDATA.HOME) ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate(ROUTINGDATA.HOME);
                      window.scrollTo(0, 0);
                    }}
                  >
                    Home
                  </button>
                </div>
              </li>
              <li>
                <div className="dropdown">
                  <button
                    className={`dropbtn ${
                      isActive(ROUTINGDATA.COURSE) ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate(ROUTINGDATA.COURSE);
                      window.scrollTo(0, 0);
                    }}
                  >
                    Courses
                  </button>
                </div>
              </li>
              <li>
                <div className="dropdown">
                  <button
                    className={`dropbtn ${
                      isActive(ROUTINGDATA.WORKSHOP) ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate(ROUTINGDATA.WORKSHOP);
                      window.scrollTo(0, 0);
                    }}
                  >
                    Workshops
                  </button>
                </div>
              </li>
              <li>
                <div className="dropdown">
                  <button
                    className={`dropbtn ${
                      isActive(ROUTINGDATA.ABOUTUS) ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate(ROUTINGDATA.ABOUTUS);
                    }}
                  >
                    About Us
                  </button>
                </div>
              </li>
              <li>
                <div className="dropdown">
                  <button
                    className={`dropbtn ${
                      isActive(ROUTINGDATA.CONTACT) ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate(ROUTINGDATA.CONTACT);
                    }}
                  >
                    Contact Us
                  </button>
                </div>
              </li>
              <li>
                <div className="dropdown">
                  <button
                    className={`dropbtn ${
                      isActive(ROUTINGDATA.BLOG) ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate(ROUTINGDATA.BLOG);
                    }}
                  >
                    Blog
                  </button>
                </div>
              </li>
              {data ? (
                <li>
                  <AccountCircleIcon
                    sx={{ mb: -0.7, cursor: "pointer" }}
                    onMouseLeave={handleMouseLeave}
                    onMouseEnter={handleMouseEnter}
                  />
                  {showProfile ? (
                    <div
                      onMouseLeave={handleMouseLeave}
                      onMouseEnter={handleMouseEnter}
                      style={{
                        position: "absolute",
                        backgroundColor: "white",
                        padding: "1rem",
                        marginLeft: "-3rem",
                        zIndex: 9999,
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1rem",
                          padding: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(ROUTINGDATA.PROFILE);
                        }}
                      >
                        Profile
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          padding: 2,
                          cursor: "pointer",
                        }}
                        onClick={handleProfileClick}
                      >
                        Logout
                      </p>
                    </div>
                  ) : null}
                </li>
              ) : (
                <>
                  <li>
                    <Button
                      variant="outlined"
                      onClick={handleLoginClick}
                      sx={{
                        backgroundColor: "#fff",
                        textTransform: "capitalize",
                        fontWeight: 550,
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        borderRadius: "25px",
                        color: "#333",
                        borderColor: "#39b54a",
                        "&:hover": {
                          textTransform: "capitalize",
                          color: "#333",
                          backgroundColor: "#fff",
                          borderColor: "#39b54a",
                          fontWeight: 550,
                          borderRadius: "25px",
                        },
                      }}
                    >
                      Login
                    </Button>
                  </li>
                  <li>
                    <Button
                      variant="contained"
                      onClick={handleSignupClick}
                      sx={{
                        backgroundColor: "#39b54a",
                        textTransform: "capitalize",
                        fontWeight: 550,
                        color: "white",
                        borderColor: "#39b54a",
                        borderRadius: "25px",
                        "&:hover": {
                          textTransform: "capitalize",
                          color: "white",
                          backgroundColor: "#39b54a",
                          borderColor: "#39b54a",
                          fontWeight: 550,
                          borderRadius: "25px",
                        },
                      }}
                    >
                      Sign Up
                    </Button>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </header>
      <Drawer
        className="QDrawer"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "white",
          },
        }}
        variant="persistent"
        anchor="left"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <DrawerHeader
          sx={{
            backgroundColor: "#39b54a",
            color: "white",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          <p>Menu</p>{" "}
          <CloseIcon sx={{ ml: 0.3 }} onClick={toggleDrawer(false)} />
        </DrawerHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            paddingInline: "2rem",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          <p
            onClick={() => {
              navigate(ROUTINGDATA.HOME);
              window.scrollTo(0, 0);
            }}
          >
            Home
          </p>

          <p
            onClick={() => {
              navigate(ROUTINGDATA.COURSE);
              window.scrollTo(0, 0);
            }}
          >
            Courses
          </p>

          <p
            onClick={() => {
              navigate(ROUTINGDATA.WORKSHOP);
              window.scrollTo(0, 0);
            }}
          >
            Workshops
          </p>

          <p
            onClick={() => {
              navigate(ROUTINGDATA.ABOUTUS);
              window.scrollTo(0, 0);
            }}
          >
            About Us
          </p>

          <p
            onClick={() => {
              navigate(ROUTINGDATA.CONTACT);
            }}
          >
            Contact Us
          </p>
          <p
            onClick={() => {
              navigate(ROUTINGDATA.BLOG);
            }}
          >
            Blog
          </p>

          {data ? (
            <>
              <Tooltip title={data}>
                <AccountCircleIcon
                  sx={{ mb: -0.7, cursor: "pointer", mt: 1 }}
                />
                <text>Profile</text>
              </Tooltip>
              <p
                style={{
                  cursor: "pointer",
                }}
                onClick={handleProfileClick}
              >
                Logout
              </p>

            </>
          ) : (
            <>
              {" "}
              <Button
                variant="outlined"
                onClick={handleLoginClick}
                sx={{
                  backgroundColor: "#fff",
                  textTransform: "capitalize",
                  fontWeight: 550,
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  borderRadius: "25px",
                  color: "#333",
                  borderColor: "#39b54a",
                  "&:hover": {
                    textTransform: "capitalize",
                    color: "#333",
                    backgroundColor: "#fff",
                    borderColor: "#39b54a",
                    fontWeight: 550,
                    borderRadius: "25px",
                  },
                }}
              >
                Login
              </Button>
              <br />
              <Button
                variant="contained"
                onClick={handleSignupClick}
                sx={{
                  backgroundColor: "#39b54a",
                  textTransform: "capitalize",
                  fontWeight: 550,
                  color: "white",
                  borderColor: "#39b54a",
                  borderRadius: "25px",
                  "&:hover": {
                    textTransform: "capitalize",
                    color: "white",
                    backgroundColor: "#39b54a",
                    borderColor: "#39b54a",
                    fontWeight: 550,
                    borderRadius: "25px",
                  },
                }}
              >
                Sign Up
              </Button>
            </>
          )}
        </div>
      </Drawer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Header;
