import PricePolicy from "./PricePolicy";

export const ROUTINGDATA = {
  HOME: "/",
  PRIVACY: "/PrivacyPolicy",
  LOGIN: "/Login",
  DETAILSPAGE: "/detailsPage",
  COURSE: "/course",
  WORKSHOP: "/workshop",
  TERMSCONDITIONS: "/termsConditions",
  SIGNUP: "/Signup",
  CONTACT: "/ContactUs",
  CATEGORYPAGE: "/CategoryPage",
  WORKSHOPDETAIL: "/workshopDetails",
  ABOUTUS: "/AboutUs",
  ForgetPassword: "/ForgetPassword",
  ChangePassword: "/ChangePassword",
  ConfirmMail: "/ConfirmMail",
  PRICEPOLICY: "/PricePolicy",
  REFUNDPOLICY: "/RefundPolicy",
  BLOG: "/Blog",
  BLOGDETAILS: "/BlogDetails",
  PROFILE:"/Profile",
};
