import { Card, Divider, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import useSupabase from "../utils/useSupabase";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "./RoudingData";
import { Link } from "react-router-dom";

function ExploreWorkshops() {
  const [workshop, setWorkshop] = useState([]);
  const supabase = useSupabase();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    fetchWorkshop();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchWorkshop = async () => {
    const { data } = await supabase
      .from("workshop")
      .select()
      .eq("isActive", true)
      .limit(4);
    setWorkshop(data);
  };

  return (
    <div
      style={{
        padding: "40px",
        paddingTop: isMobile ? "12px" : "18px",
        backgroundColor: "hsl(0, 0%, 100%)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          paddingBottom: "5px",
        }}
      >
        <h2 style={{ textAlign: "center", margin: "0", flexGrow: "1" }}>
          Explore Workshop
        </h2>
        <Link
          to={ROUTINGDATA.WORKSHOP}
          style={{
            position: isMobile ? "static" : "absolute",
            right: "0",
            color: "green",
            marginTop: isMobile ? "10px" : "0",
          }}
        >
          View All
        </Link>
      </div>
      <br />
      <Grid container spacing={2}>
        {workshop &&
          workshop.map((data) => (
            <Grid
              key={data.id}
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ cursor: "pointer" }}
            >
              <Card
                className="CourseCard"
                sx={{
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  "&:hover": {
                    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
                  },
                  borderRadius: "15px",
                }}
                onClick={() => {
                  navigate(`${ROUTINGDATA.WORKSHOPDETAIL}?id=${data.id}`);
                  window.scrollTo(0, 0);
                }}
              >
                <img
                  src={data.imageUrl}
                  alt="courseImage"
                  style={{ height: "150px", width: "100%" }}
                />
                <div
                  className="courseCard"
                  style={{
                    padding: isMobile ? "0.5rem" : "1rem",
                    color: "#505050",
                    fontWeight: 600,
                  }}
                >
                  <p
                    style={{
                      color: "#303030",
                      fontSize: isMobile ? "16px" : "20px",
                      fontWeight: 600,
                      margin: 0,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "270px",
                    }}
                  >
                    {data.workshopName}
                  </p>
                  <div style={{ display: "flex", margin: "-7px 0px" }}>
                    <AccessTimeIcon sx={{ mt: 1.8, pr: 0.4 }} />
                    <p>
                      {dayjs(data.workshopTime).format("DD")} {data.workshopDay}
                      , {dayjs(data.workshopTime).format("hh:mm a")}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "-1.5rem",
                      marginBottom: "-1.4rem",
                    }}
                  >
                    <SignalCellularAltIcon sx={{ mt: 1.8, pr: 0.4 }} />
                    <p>{data.label} Level</p>
                  </div>
                  <p> By {data.trainer}</p>
                  <Divider sx={{ border: "1px solid gray" }} />
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "-0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <del style={{ marginTop: "0.1rem" }}>
                        ₹{data.realfees}
                      </del>
                      <p
                        style={{
                          margin: 0,
                          paddingLeft: "10px",
                          color: "black",
                          fontSize: "18px",
                        }}
                      >
                        ₹{data.discountedFees || data.fees}
                      </p>
                    </div>
                    <Button
                      variant="contained"
                      sx={{
                        //   mt: "-0.6rem",
                        pt: "4px",
                        backgroundColor: "#39b54a",
                        textTransform: "capitalize",
                        fontWeight: 550,
                        color: "white",
                        borderColor: "#39b54a",
                        borderRadius: "25px",
                        "&:hover": {
                          textTransform: "capitalize",
                          color: "white",
                          backgroundColor: "#39b54a",
                          borderColor: "#39b54a",
                          fontWeight: 550,
                          borderRadius: "25px",
                        },
                      }}
                    >
                      Know More
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default ExploreWorkshops;
