// import React from "react";
// import placement1 from "../Images/placement1.png";
// import placement2 from "../Images/placement2.png";
// import placement3 from "../Images/placement3.png";
// import placement4 from "../Images/placement4.png";
// import placement5 from "../Images/placement5.png";
// import placement6 from "../Images/placement6.png";
// import placement7 from "../Images/placement7.png";
// import placement8 from "../Images/placement8.png";
// import placement9 from "../Images/placement9.png";
// import placement10 from "../Images/placement10.png";
// import placement11 from "../Images/placement11.png";
// import placement12 from "../Images/placement12.png";
// import placement13 from "../Images/placement13.png";
// import placement14 from "../Images/placement14.png";
// import placement15 from "../Images/placement15.png";
// import placement16 from "../Images/placement16.png";
// import placement17 from "../Images/placement17.png";
// import placement18 from "../Images/placement18.png";
// import placement19 from "../Images/placement19.png";
// import placement20 from "../Images/placement20.png";
// import placement21 from "../Images/placement21.png";
// import placement22 from "../Images/placement22.png";
// import placement23 from "../Images/placement23.png";
// import placement24 from "../Images/placement24.png";
// import placement25 from "../Images/placement25.png";
// import placement26 from "../Images/placement26.png";
// import placement27 from "../Images/placement27.png";
// import placement28 from "../Images/placement28.png";
// import placement29 from "../Images/placement29.png";
// import placement30 from "../Images/placement30.png";
// import placement31 from "../Images/placement31.png";
// import placement32 from "../Images/placement32.png";
// import "./HomePage.css";
// import Marquee from "react-fast-marquee";
// const PlacementCompanies = () => {
//   return (
//     <>
//       <div style={{ backgroundColor: "#EFFFF1" }}>
//         <h2 style={{ textAlign: "center" }}>Placement Companies </h2>
//         <Marquee pauseOnHover gradient className="marqueeborder">
//           <div className="maequeepartnerimg">
//             <img src={placement1} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement2} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement3} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement4} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement5} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement6} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement7} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement8} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement9} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement10} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement11} alt="" />
//           </div>
//         </Marquee>
//         <Marquee pauseOnHover gradient className="marqueeborder">
//           <div className="maequeepartnerimg">
//             <img src={placement12} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement13} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement14} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement15} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement16} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement17} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement18} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement19} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement20} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement21} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement22} alt="" />
//           </div>
//         </Marquee>
//         <Marquee pauseOnHover gradient className="marqueeborder">
//           <div className="maequeepartnerimg">
//             <img src={placement23} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement24} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement25} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement26} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement27} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement28} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement29} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement30} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement31} alt="" />
//           </div>
//           <div className="maequeepartnerimg">
//             <img src={placement32} alt="" />
//           </div>
//         </Marquee>
//       </div>
//     </>
//   );
// };

// export default PlacementCompanies;

// import React, { useEffect, useState } from "react";
// import Marquee from "react-fast-marquee";
// import useSupabase from "../utils/useSupabase";
// import "./HomePage.css";

// const PlacementCompanies = () => {
//   const [companies, setCompanies] = useState();
//   const supabase = useSupabase();
//   useEffect(
//     () => {
//       fetchCompanies();
//     },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     []
//   );
//   const fetchCompanies = async () => {
//     const { data } = await supabase
//       .from("companies")
//       .select("imageUrl")
//       .eq("isActive", true);

//     setCompanies(data);
//   };
//   return (
//     <>
//       <h2 style={{ textAlign: "center" }}>Placement Companies </h2>
//       <Marquee pauseOnHover gradient className="marqueeborder">
//         {companies?.map((companies, index) => (
//           <div className="maequeepartnerimg">
//             <img src={companies.imageUrl} alt="" />
//           </div>
//         ))}
//       </Marquee>
//     </>
//   );
// };

// export default PlacementCompanies;
import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import useSupabase from "../utils/useSupabase";
import "./HomePage.css";

const PlacementCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const supabase = useSupabase();

  useEffect(() => {
    fetchCompanies();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCompanies = async () => {
    const { data } = await supabase
      .from("companies")
      .select("imageUrl")
      .eq("isActive", true);

    setCompanies(data);
  };

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const chunkedCompanies = chunkArray(companies, 11);

  return (
    <>
      <h2 style={{ textAlign: "center" }}>Placement Companies</h2>
      {chunkedCompanies.map((chunk, index) => (
        <Marquee
          pauseOnHover
          gradient={false}
          className="marqueeborder"
          key={index}
        >
          {chunk.map((company, index) => (
            <div key={index}>
              <img
                style={{ paddingRight: "50px" }}
                src={company.imageUrl}
                alt=""
              />
            </div>
          ))}
        </Marquee>
      ))}
    </>
  );
};

export default PlacementCompanies;
