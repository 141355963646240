import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import ContentPage from "./ContentPage";
//import ExploreCourse from "./ExploreCourse";
import ExploreWorkshops from "./ExploreWorkshops";
import ChooseUs from "./ChooseUs";
import ExploreCategory from "./ExploreCategory";
import WebsiteReview from "./WebsiteReview";
import HomeAboutUs from "./HomeAboutUs";
import HomeCoursePage from "./HomeCoursePage";
import TrainingCertification from "./TrainingCertification";
import PlacementCompanies from "./PlacementCompanies";
//import Testimonials from "./Testimonials";

function HomePage() {
  return (
    <div>
      <Header />
      <br />
      <ContentPage />
      <ExploreCategory />
      {/* <ExploreCourse /> */}
      <HomeCoursePage />
      <ExploreWorkshops />
      <br />
      <WebsiteReview />
      {/* <br />
      <Testimonials /> */}
      <br />
      <ChooseUs />
      <br />
      <TrainingCertification />
      <br />
      <HomeAboutUs />
      <PlacementCompanies />
      <br />
      <Footer />
    </div>
  );
}

export default HomePage;
