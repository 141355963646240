import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Card, FormControl, FormLabel } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "./RoudingData";
import useSupabase from "../utils/useSupabase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton, InputAdornment } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Validation schema using Yup
const validationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ChangePassword = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const supabase = useSupabase();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const handleClose = () => {
    setOpen(false);
    if (isLogin === true) {
      navigate("/");
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleTogglePasswordVisibilitys = () => {
    setShowPasswords((prevShowPasswords) => !prevShowPasswords);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        console.log("password", values.newPassword);

        if (values.newPassword !== values.confirmPassword) {
          setError("New password and confirm password do not match");
          setOpen(true);
          return;
        }

        console.log("password1", values.newPassword);

        const { data, error } = supabase.auth.updateUser({
          password: values.newPassword,
        });

        if (error) {
          console.error("Supabase updateUser error:", error);
          setError(error.message);
          setOpen(true);
          return;
        }

        console.log("Password updated successfully", data);
        setError("Password updated successfully");
        formik.resetForm();
        setIsLogin(true);
        handleClickOpen();
      } catch (err) {
        console.error("Error in onSubmit function:", err);
        setError("An unexpected error occurred. Please try again.");
        setOpen(true);
      }
    },
  });

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {isLogin ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "70px", color: "#4CAF50" }}
              />
            </div>
          ) : null}
          <DialogContentText id="alert-dialog-slide-description">
            {error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "20px",
              display: "block",
              background: "#39B54A",
              borderRadius: "40px",
              paddingRight: "22px",
              paddingLeft: "22px",
              color: "white",
              textTransform: "capitalize",
              "&:hover": {
                textTransform: "capitalize",
                color: "#333",
                background: "#39B54A",
                borderColor: "#39b54a",
                fontWeight: 550,
                borderRadius: "25px",
              },
            }}
            onClick={handleClose}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Header />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card sx={{ p: 4, margin: "2rem 0" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "36px",
              }}
            >
              Change Password
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 1 }}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "500PX",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "19.36px",
                    }}
                  >
                    New Password
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Enter new password"
                    required
                    fullWidth
                    id="newPassword"
                    type={showPasswords ? "text" : "password"}
                    name="newPassword"
                    autoComplete="new-password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newPassword}
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    helperText={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibilitys}>
                            {showPasswords ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 3 }}>
                  
                 <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "500PX",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "19.36px",
                    }}
                  >
                    Confirm New Password
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Enter new password"
                    required
                    fullWidth
                    id="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    name="confirmPassword"
                    autoComplete="new-password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 3,
                    backgroundColor: "#39b54a",
                    textTransform: "capitalize",
                    fontWeight: 550,
                    color: "white",
                    borderColor: "#39b54a",
                    borderRadius: "25px",
                    "&:hover": {
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: "#39b54a",
                      borderColor: "#39b54a",
                      fontWeight: 550,
                      borderRadius: "25px",
                    },
                  }}
                >
                  Change Password
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default ChangePassword;
