import React, { Fragment } from "react";
import Header from "./Components/Header";
import { Fullscreen } from "@mui/icons-material";
import Footer from "./Components/Footer";
import { useMediaQuery } from "@mui/material";

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Fragment>
      <Header />
      <div
        style={{
          width: Fullscreen,
          paddingLeft: isMobile ? "30PX" : "150px",
          paddingRight: isMobile ? "30PX" : "150px",
        }}
      >
        <br />
        <div
          style={{
            width: Fullscreen,
          }}
        >
          <h1 style={{ font: "Inter", fontSize: "28px" }}>Privacy Policy</h1>

          <p style={{ font: "Inter", fontSize: "18px" }}>
            The usage of www.educampustech.com (hereinafter referred to as
            “educampustech” or “ us” or “we” or “our” or “Company”) is governed
            by the below Privacy policy.
          </p>
          <h3
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
            }}
          >
            1. Introduction
          </h3>
          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Welcome to www.educampustech.com (“we”, “our”, “us”). Your privacy
            is critically important to us. This Privacy Policy explains how we
            collect, use, disclose, and safeguard your information when you
            visit our website www.educampustech.com , and use our services. By
            using our website and services, you agree to the collection and use
            of information in accordance with this policy.
          </p>
          <h3
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
            }}
          >
            2. Information We Collect
          </h3>
          <h3
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
            }}
          >
            a. Personal Data
          </h3>
          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            While using our website, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information may include, but
            is not limited to:
          </p>
          <p
            style={{
              paddingLeft: "50px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Whatsapp number</li>
          </p>
          <h3
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
            }}
          >
            b. Usage Data
          </h3>
          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            We may also collect information that your browser sends whenever you
            visit our website or when you access the website by or through a
            mobile device. This Usage Data may include information such as your
            computer’s Internet Protocol (IP) address, browser type, browser
            version, the pages of our website that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers, and other diagnostic data
          </p>
          <h3
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
            }}
          >
            3. How We Use Your Information
          </h3>
          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            We use the collected data for various purposes:
          </p>
          <p
            style={{
              paddingLeft: "50px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            <li>To provide and maintain our service</li>
            <li>To notify you about changes to our service</li>
            <li>
              To allow you to participate in interactive features of our service
              when you choose to do so
            </li>
            <li>To provide customer support</li>
            <li>
              To gather analysis or valuable information so that we can improve
              our service
            </li>
            <li>To monitor the usage of our service</li>
            <li>To detect, prevent, and address technical issues</li>
            <li>To fulfill any other purpose for which you provide it</li>
          </p>
          <h3
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
            }}
          >
            4. Security of Your Information
          </h3>
          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are perfect or impenetrable, and no method of data transmission can
            be guaranteed against any interception or other type of misuse.
          </p>
          <h3
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
            }}
          >
            5. Changes to This Privacy Policy
          </h3>
          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>
      </div>
      <br />
      <Footer />
    </Fragment>
  );
};

export default PrivacyPolicy;
