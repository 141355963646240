import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useMediaQuery } from "@mui/material";

const RefundPolicy = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Header />
      <div
        style={{
          paddingLeft: isMobile ? "30PX" : "150px",
          paddingRight: isMobile ? "30PX" : "150px",
          font: "Inter",
          textAlign: "justify",
          fontSize: "18px",
        }}
      >
        <h1 style={{ font: "Inter", fontSize: "28px" }}>
        Refund Policy
        </h1>


        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          Course Cancellation by EduCampus Technologies
        </h3>
        <ul>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            If a course is canceled by EduCampus Technologies, students will receive a full refund of the course fees.
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
              paddingTop:"10px"
            }}
          >
           Students will be notified of the cancellation via email and provided with options for rescheduling or alternative courses if available.
          </li>
        </ul>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          Cancellation by Students
        </h3>
        <ul>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
Students may request a refund if they cancel their enrollment within a specific timeframe. The details of the refund policy, including eligible timeframes and any applicable administrative fees, will be provided during the registration process.          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
              paddingTop:"10px"
            }}
          >
Requests for refunds must be submitted in writing to our support team.
        </li>
        </ul>
        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          Non-Refundable Fees
        </h3>
        <ul>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
Certain fees, such as administrative fees or material costs, may be non-refundable. These will be clearly communicated during the enrollment process.      </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
