import { Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import useSupabase from "../utils/useSupabase";
import { ROUTINGDATA } from "./RoudingData";
import { useNavigate } from "react-router-dom";

const ExploreCategory = () => {
  const supabase = useSupabase();
  const [subcategory, setSubcategory] = useState([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    fetchSubcategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSubcategory = async () => {
    const { data } = await supabase
      .from("subcategory")
      .select()
      .eq("isActive", true);

    setSubcategory(data);
  };

  return (
    <div style={{ marginTop: "20px", paddingInline: "" }}>
      <h2 style={{ textAlign: "center" }}>Explore by Category</h2>
      <div
        style={{
          display: "flex",
          overflowX: isMobile ? "auto" : "hidden",
          flexWrap: isMobile ? "nowrap" : "wrap",
          msOverflowStyle: "none", 
          scrollbarWidth: "none",
        }}
      >
        {subcategory?.map((subcategory, index) => (
          <div
            key={index}
            style={{
              flex: "0 0 auto",
              width: isMobile ? "80%" : "23%",
              margin: isMobile ? "0 10px" : "0 1%",
              padding: "10px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #084FC7",
                borderRadius: "5px",
                cursor: "pointer",
                padding: "2px",
              }}
              onClick={() => {
                navigate(
                  `${ROUTINGDATA.CATEGORYPAGE}?name=${subcategory.subcategoryCode}`
                );
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={subcategory.imageUrl}
                alt="CourseLogo"
                style={{
                  height: isMobile ? "20px" : "40px",
                  width: isMobile ? "40px" : "60px",
                  margin: "0.5rem",
                  backgroundColor: "#D0E3FF",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              />
              <Typography
                variant="body1"
                style={{
                  color: "#4F4F4F",
                  fontWeight: 500,
                  marginRight: "auto",
                  fontSize: isMobile? "12px":"",
                }}
              >
                {subcategory.subcategoryName}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExploreCategory;
