import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ExploreCourse from "./ExploreCourse";
import ExploreWorkshops from "./ExploreWorkshops";
import Rating from "@mui/material/Rating";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "./RoudingData";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { v4 as uuidv4 } from "uuid";
import {
  Grid,
  Button,
  useMediaQuery,
  Modal,
  Box,
  IconButton,
  TextField,
  Card,
  InputLabel,
  Typography,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContent,
  Tooltip,
} from "@mui/material";
import Image1 from "../Images/Illustration (1).png";
import Image2 from "../Images/OBJECTS.png";
import Image3 from "../Images/Illustration.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
// import Review from "../Review";
import useSupabase from "../utils/useSupabase";
import enroll from "../Images/enroll.jpg";

function DetailsPage() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const supabase = useSupabase();
  const [coursedetail, setCoursedetail] = useState([]);
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get("id");
  const [emailError, setEmailError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showRemainingSkills, setShowRemainingSkills] = useState(false);
  const [courseName, setCourseName] = useState("");

  useEffect(() => {
    fetchCourseDetail();
  }, [courseId]); // eslint-disable-line react-hooks/exhaustive-deps

  const [datas, setDatas] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = localStorage.getItem("userId");
    if (storedData) {
      setDatas(storedData);
    }
  }, []);
 
  const fetchCourseDetail = async () => {
    let { data, error } = await supabase.rpc("course_detail", {
      courseid: parseInt(courseId, 10),
    });
    if (error) {
      console.error(error);
    } else {
      setCoursedetail(data);
    }
  };

  const [openPopup, setOpenPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    whatsapp: "",
    courseName: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "email") {
      validateEmail(value);
    }
  };
  const validateEmail = (email) => {
    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(email));
  };
  useEffect(() => {
    validateForm();
  }, [formData, emailError]); // eslint-disable-line react-hooks/exhaustive-deps
  const validateForm = () => {
    const { name, email, whatsapp } = formData;
    setIsFormValid(
      name.trim() !== "" &&
        email.trim() !== "" &&
        whatsapp.trim().length === 10 &&
        !emailError
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError) {
      return;
    }
    setLoading(true); // Start loading

    try {
      const invoiceId = uuidv4();
      var options = {
        key: "rzp_live_QRGHa64MsP3yYR",
        key_secret: "ryYuXnXGjIAPklm4ZEnhVa8L",
        amount: coursedetail[0].fees * 100,
        currency: "INR",
        name: "EDU Campus",
        description: "for testing purpose",
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.whatsapp,
        },
        handler: async function (response) {
          const paymentId = response.razorpay_payment_id;
          console.log("payment id", paymentId);

          try {
            // Insert data into the 'enroll' table
            const { data, error } = await supabase
              .from("enrollCourses")
              .insert([
                {
                  name: formData.name,
                  email: formData.email,
                  whatsappNo: formData.whatsapp,
                  courseId: parseInt(courseId, 10),
                  courseName: formData.courseName,
                },
              ]);
            if (error) {
              throw error;
            }
            console.log("Data inserted successfully:", data);
            const { data: payment } = await supabase.from("payment").insert([
              {
                invoiceId: invoiceId,
                paymentId: paymentId,
                totalAmount: coursedetail[0].fees,
                courseId: parseInt(courseId, 10),
                studentName: formData.name,
                contactNo: formData.whatsapp,
              },
            ]);
            handleClosePopup();
            setOpenSnackbar(true);
            // Send email after successful payment and data insertion
            const emailContent = `
              Dear SACHITHANANTHAM SANTHOSAM,<br/>
              <p>We are pleased to inform you that a new student has enrolled in one of our courses. Please find the details of the enrollment below:</p>
              <h3>Student Details: </h3>
              <p><strong>Name:</strong> ${formData.name}</p>
              <p><strong>Email:</strong> ${formData.email}</p>
              <p><strong>Phone Number:</strong> ${formData.whatsapp}</p>
              <br/>
              <h3>Course Details:</h3> 
              <p><strong>Course Name:</strong> ${courseName}</p>
              <p><strong>Course ID:</strong> ${courseId}</p>
              <br/>
              <p>If you need any further information or assistance regarding this enrollment, please feel free to contact the student directly or reach out to our support team.</p>
              <br/>
              Thank you for your attention.
              <br/>
              <br/>
              Best regards, <br/>
              Team EduCampus Technologies
            `;

            const response = await fetch(
              "https://usgs-mail-server.onrender.com/usgs-services/sendmail",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  html: emailContent,
                  to: "sachin.s@educampustech.in",
                  subject: "Course Enrollment",
                }),
              }
            );
            const userEmailContent = `
            Dear ${formData.name},<br/>
            <p>Thank you for enrolling in our course. Please find the details of your enrollment below:</p>
            <h3>Course Details:</h3> 
            <p><strong>Course Name:</strong> ${courseName}</p>
            <p><strong>Course ID:</strong> ${courseId}</p>
            <br/>
            <p>If you need any further information or assistance regarding this course, please feel free to reach out to our support team.</p>
            <br/>
            Thank you for choosing EduCampus Technologies.
            <br/>
            <br/>
            Best regards, <br/>
            Team EduCampus Technologies
          `;

            await fetch(
              "https://usgs-mail-server.onrender.com/usgs-services/sendmail",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  html: userEmailContent,
                  to: formData.email,
                  subject: "Course Enrollment Confirmation",
                }),
              }
            );

            if (response.ok) {
              console.log("Email sent successfully");
            } else {
              console.error("Failed to send email");
            }
          } catch (error) {
            console.error("Error inserting data:", error.message);
          }
        },
        // theme: {
        //   color: "#07a291db",
        // },
      };
      var pay = new window.Razorpay(options);
      pay.open();
    } catch (error) {
      console.error("Error inserting data:", error.message);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleOpenPopup = (name) => {
    setCourseName(name); // Set the course name when opening the popup
    setFormData((prevData) => ({
      ...prevData,
      courseName: name,
    }));
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setFormData({
      name: "",
      email: "",
      whatsapp: "",
    });
    setOpenPopup(false);
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const handleSkillsClick = () => {
    setShowRemainingSkills(!showRemainingSkills);
  };
  return (
    <div>
      <Header />
      {coursedetail.map((item) => {
        const backgroundImageStyle = {
          backgroundImage: `url(${item.imageUrl})`,
        };
        const skills = item.skills.split(",").map((skill) => skill.trim());
        const displayedSkillsCount = isMobile ? 3 : 5;
        const remainingSkills = skills.slice(displayedSkillsCount);
        return (
          <div
            className="detailPageImage1"
            key={item.detailId}
            style={backgroundImageStyle}
          >
            <Grid container>
              <Grid md={6} sx={{ p: 5 }}>
                <h1>{item.courseName}</h1>
                <h4>By {item.trainer} </h4>
                <Rating
                  name="half-rating-read"
                  defaultValue={4.5}
                  precision={0.5}
                  readOnly
                />
                <p style={{ textAlign: "justify" }}>{item.courseDesc}</p>
                {skills.slice(0, displayedSkillsCount).map((skill, index) => (
                  <Chip
                    key={index}
                    sx={{ color: "white", margin: "5px" }}
                    label={skill}
                    variant="outlined"
                  />
                ))}
                {skills.length > displayedSkillsCount && (
                  <>
                    {isMobile ? (
                      <>
                        <Chip
                          sx={{ color: "white", margin: "5px" }}
                          label={
                            showRemainingSkills
                              ? "Show Less"
                              : `+${remainingSkills.length} skills`
                          }
                          variant="outlined"
                          onClick={handleSkillsClick}
                        />
                        {showRemainingSkills && (
                          <Box sx={{ display: "flex", flexWrap: "wrap", p: 1 }}>
                            {remainingSkills.map((skill, index) => (
                              <Chip
                                key={index}
                                sx={{ color: "white", margin: "5px" }}
                                label={skill}
                                variant="outlined"
                              />
                            ))}
                          </Box>
                        )}
                      </>
                    ) : (
                      <Tooltip
                        title={
                          <Box sx={{ display: "flex", flexWrap: "wrap", p: 1 }}>
                            {remainingSkills.map((skill, index) => (
                              <Chip
                                key={index}
                                sx={{ color: "white", margin: "5px" }}
                                label={skill}
                                variant="outlined"
                              />
                            ))}
                          </Box>
                        }
                      >
                        <Chip
                          sx={{ color: "white", margin: "5px" }}
                          label={`+${remainingSkills.length} skills`}
                          variant="outlined"
                        />
                      </Tooltip>
                    )}
                  </>
                )}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <del
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      margin: "0 10px 0 0",
                      //color: "black",
                    }}
                  >
                    ₹{item.realfees}
                  </del>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      margin: "0",
                    }}
                  >
                    ₹{item.fees}
                  </p>
                </div>

                <Button
                  variant="contained"
                  sx={{
                    mt: "1rem",
                    backgroundColor: "#39b54a",
                    textTransform: "capitalize",
                    fontWeight: 550,
                    color: "white",
                    borderColor: "#39b54a",
                    borderRadius: "25px",
                    "&:hover": {
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: "#39b54a",
                      borderColor: "#39b54a",
                      fontWeight: 550,
                      borderRadius: "25px",
                    },
                  }}
                  onClick={() => datas ? handleOpenPopup(item.courseName) : navigate(ROUTINGDATA.LOGIN)}
                >
                  Enroll now
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      })}
      <Modal
        open={openPopup}
        onClose={handleClosePopup}
        aria-labelledby="form-modal-title"
        aria-describedby="form-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "90%" : 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <IconButton onClick={handleClosePopup} sx={{ float: "left" }}>
            <ArrowBackIcon />
          </IconButton>
          <Card sx={{ padding: 2, mt: isMobile ? 2 : 5 }}>
            <img
              src={enroll}
              alt="form-header"
              style={{
                width: "100%",
                height: isMobile ? "10vh" : "20vh",
                borderRadius: "8px",
              }}
            />
            <form onSubmit={handleSubmit}>
              <InputLabel sx={{ marginTop: 2 }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  Name
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                size="small"
                fullWidth
                placeholder="Enter your name"
                variant="outlined"
                required
                name="name"
                value={formData.name}
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, "");
                }}
              />
              <InputLabel sx={{ marginTop: 2 }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  Enter your Email id
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                size="small"
                fullWidth
                placeholder="Enter your mail id"
                variant="outlined"
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={emailError}
                helperText={
                  emailError ? "Please enter a valid email address" : ""
                }
              />
              <InputLabel sx={{ marginTop: 2 }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  WhatsApp number
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                size="small"
                fullWidth
                placeholder="Enter your current number"
                variant="outlined"
                required
                name="whatsapp"
                value={formData.whatsapp}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target.value.replace(/\D/g, "");
                  const trimmedInput = input.slice(0, 10);
                  e.target.value = trimmedInput;
                  handleChange(e);
                }}
              />
              <InputLabel sx={{ marginTop: 2 }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  Course Name
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                size="small"
                fullWidth
                name="courseName"
                value={courseName}
                readOnly
                InputProps={{
                  readOnly: true,
                }}
              />
              <Button
                variant="contained"
                color="success"
                type="submit"
                sx={{ mt: 3 }}
                disabled={!isFormValid}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          </Card>
        </Box>
      </Modal>

      <Dialog open={openSnackbar} onClose={handleSnackbarClose}>
        <DialogContent
          sx={{
            textAlign: "center",
            padding: "40px 20px",
            color: "#fff",
            borderRadius: "8px",
          }}
        >
          <CheckCircleOutlineIcon sx={{ fontSize: "70px", color: "#4CAF50" }} />
          <DialogTitle
            sx={{ fontSize: "24px", fontWeight: "bold", color: "black" }}
          >
            Thank You!
          </DialogTitle>
          <Typography sx={{ marginTop: "10px", color: "black" }}>
            Thank you for submitting your details and enroll in our Course! Our
            team will be in touch you shortly.
          </Typography>
          <Button
            onClick={handleSnackbarClose}
            variant="contained"
            sx={{
              marginTop: "20px",
              backgroundColor: "#4CAF50",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#45A049",
              },
            }}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
      <br />
      <div style={{ backgroundColor: "#F1F6FF" }}>
        <h2 style={{ textAlign: "center", paddingTop: "5px" }}>
          What is Included?
        </h2>
        <Grid
          container
          sx={{
            color: "#2E2E2E",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            paddingInline: "7rem",
            paddingBottom: "30px",
            justifyContent: "center",
          }}
        >
          <Grid
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: isMobile ? "30PX" : "0px",
            }}
          >
            <div
              style={{
                border: "1px solid #084FC7",
                borderRadius: "5px",
                padding: "0.5rem 4rem",
              }}
            >
              <img
                src={Image1}
                alt="Image1"
                style={{ height: "150px", width: "150px" }}
              />
              <p style={{ margin: "4px" }}>Live Project</p>
            </div>
          </Grid>
          <Grid
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: isMobile ? "30PX" : "0px",
            }}
          >
            <div
              style={{
                border: "1px solid #084FC7",
                borderRadius: "5px",
                padding: "0.5rem 4rem",
              }}
            >
              <img
                src={Image2}
                alt="Image2"
                style={{ height: "150px", width: "150px" }}
              />
              <p style={{ margin: "4px" }}>Certification</p>
            </div>
          </Grid>
          <Grid
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: isMobile ? "30PX" : "0px",
            }}
          >
            <div
              style={{
                border: "1px solid #084FC7",
                borderRadius: "5px",
                padding: "0.5rem 4rem",
              }}
            >
              <img
                src={Image3}
                alt="Image3"
                style={{ height: "150px", width: "150px" }}
              />
              <p style={{ margin: "4px" }}>Job Guidance</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingInline: isMobile ? "2rem" : "6rem" }}>
        <h2 style={{ textAlign: "center" }}>
          Topics that Covered in this Course
        </h2>

        {coursedetail.map((item) => {
          return (
            <div key={item.id}>
              {[...Array(5)].map(
                (
                  _,
                  index // Assuming there are always 6 topics and descriptions
                ) => (
                  <Accordion
                    key={`${item.id}-topic-${index + 1}`}
                    sx={{
                      mb: 1,
                      borderRadius: "5px",
                      backgroundColor: "#F6FAFF",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${item.id}-content`}
                      id={`panel${item.id}-header`}
                      sx={{
                        color: "#444BE2",
                        border: "1px solid #97BEFF",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography>{item[`topic${index + 1}`]}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        color: "#444BE2",
                        border: "1px solid #97BEFF",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography>{item[`desc${index + 1}`]}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )
              )}
            </div>
          );
        })}
      </div>
      <br />
      {/* <Review courseId={courseId} /> */}
      <ExploreCourse />
      <ExploreWorkshops />
      <br />
      <div
        style={{
          paddingInline: isMobile ? "2rem" : "6rem",
        }}
      >
        <h2 style={{ textAlign: "center", marginTop: "-10px" }}>FAQ</h2>
        {coursedetail.map((item) => {
          return (
            <div key={item.id}>
              {[...Array(6)].map((_, index) => (
                <Accordion
                  key={`${item.id}-topic-${index + 1}`}
                  sx={{
                    mb: 1,
                    borderRadius: "5px",
                    backgroundColor: "#EEEEEE",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${item.id}-content`}
                    id={`panel${item.id}-header`}
                    sx={{
                      color: "#2C2C2C",
                      border: "1px solid #C9C9C9",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography>{item[`faq${index + 1}`]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      color: "##2C2C2C",
                      border: "1px solid #C9C9C9",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography>{item[`answer${index + 1}`]}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          );
        })}
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default DetailsPage;
