import React, { Fragment } from "react";
import Header from "./Header";
import { Fullscreen } from "@mui/icons-material";
import Footer from "./Footer";
import { useMediaQuery } from "@mui/material";

const AboutUs = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Fragment>
      <Header />
      <div
        style={{
          width: Fullscreen,
          paddingLeft: isMobile ? "30PX" : "150px",
          paddingRight: isMobile ? "30PX" : "150px",
        }}
      >
        <br />
        <div
          style={{
            width: Fullscreen,
          }}
        >
          <h1 style={{ font: "Inter", fontSize: "28px" }}>About Us</h1>

          <p style={{ font: "Inter", fontSize: "18px" }}>
            EduCampus Technologies is a premier IT training company dedicated to
            empowering individuals with the skills and knowledge required to
            excel in the technology-driven world. Our journey is marked by a
            commitment to quality education, innovative training methods, and a
            vision to shape the future of IT professionals.
          </p>
          <h3
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
            }}
          >
            Our History
          </h3>
          <li
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
              fontWeight: "bold",
            }}
          >
            2006: Formation of Bala's Placement and Computer Education
          </li>
          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Our story began in 2006 with the establishment of Bala's Placement
            and Computer Education. Over the years, we have trained 2100
            students, laying a strong foundation in the field of IT training.
          </p>
          <li
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
              fontWeight: "bold",
            }}
          >
            2017: Birth of EduCampus Technologies
          </li>

          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            In 2017, EduCampus Technologies was officially formed, continuing
            our mission to provide top-notch IT education. This year marked a
            significant milestone with 400 students successfully trained under
            our new banner.
          </p>
          <li
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
              fontWeight: "bold",
            }}
          >
            2023: Launch of Velaivendum.com
          </li>

          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            We launched Velaivendum.com in 2023, a platform designed to bridge
            the gap between job seekers and employers. This initiative has
            expanded our reach and enhanced our ability to support students in
            their career aspirations.
          </p>
          <li
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
              fontWeight: "bold",
            }}
          >
            2024: Expanding Our Impact
          </li>
          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Looking ahead, we have ambitious plans for 2024, with a goal to
            train 3000 students. Additionally, we are excited to launch the
            Velaivendum Job Seeker mobile app in June 2024, further enhancing
            our support for job seekers.
          </p>

          <h3
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
            }}
          >
            Our Vision
          </h3>
          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            At EduCampus Technologies, we envision a future where every
            individual has access to the highest quality IT training, enabling
            them to achieve their professional goals. Our comprehensive training
            programs are designed to equip students with the latest industry
            skills, making them highly competitive in the job market.
          </p>
          <h3
            style={{
              paddingLeft: "13px",
              fontFamily: "Inter",
              fontSize: "21px",
            }}
          >
            Our Mission
          </h3>
          <p
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Our mission is to deliver exceptional IT training through innovative
            teaching methods and practical hands-on experience. We aim to foster
            an environment of continuous learning and development, ensuring that
            our students are well-prepared to meet the challenges of the
            evolving tech industry.
          </p>
        </div>
      </div>
      <br />
      <Footer />
    </Fragment>
  );
};

export default AboutUs;
