import { createClient } from "@supabase/supabase-js";
// Create a single supabase client for interacting with your database
export const supabase = createClient(
  "https://zwttwsulhwevsgteznxo.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp3dHR3c3VsaHdldnNndGV6bnhvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTM4NTU4ODcsImV4cCI6MjAyOTQzMTg4N30.0Hpq2RJo-PnRonEEaaNewVgLY0NlKvG2AEcQ7JImBWA"
);

const useSupabase = () => {
  return supabase;
};

export default useSupabase;
