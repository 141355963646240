import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  FormControl,
  InputAdornment,
  Card,
  useMediaQuery,
  DialogContent,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import EmailIcon from "@mui/icons-material/Email";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import useSupabase from "../utils/useSupabase";
import Dialog from "@mui/material/Dialog";
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email Address Required"),
});

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const supabase = useSupabase();
  const isMobile = useMediaQuery("(max-width:600px)");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { data: users, error } = await supabase
        .from("userProfile")
        .select("*")
        .eq("email", values.email);

      if (error) {
        console.error("Error fetching user profile:", error.message);
        setError("An error occurred while checking the email.");
        setOpen(true);
        return;
      }

      if (users.length === 0) {
        formik.handleReset();
        setError("Email not found.");
        setOpen(true);
        return;
      }
      const user = users[0];

      if (user.userType !== "Student") {
        formik.handleReset();
        setError("You are not authorized to reset password.");
        setOpen(true);
        return;
      }
      // If the email exists and userType is customer, send the reset password link
      const { data, error: resetError } =
        await supabase.auth.resetPasswordForEmail(values.email, {
          redirectTo: `https://www.educampustech.com/#/ChangePassword`,
        });

      if (resetError) {
        formik.handleReset();
        setError("Error resetting password");
        setOpen(true);
        return;
      } else {
        formik.handleReset();
        setSuccess("Password reset link sent successfully. Please check your email.");
        setOpen(true);
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
    setError("");
    setSuccess("");
  };

  const handleLogin = () => {
    setOpen(false);
    navigate("/login");
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            textAlign: "center",
            padding: "40px 20px",
            color: "#fff",
            borderRadius: "8px",
            width: "400px"
          }}
        >
          {error ? (
            <>
              <DangerousOutlinedIcon sx={{ fontSize: "70px", color: "red" }} />
              <Typography sx={{ fontSize: "24px", marginTop: "10px", color: "black" }}>
                {error}
              </Typography>
            </>
          ) : (
            <>
              <CheckCircleOutlineIcon sx={{ fontSize: "70px", color: "green" }} />
              <Typography sx={{ fontSize: "24px", marginTop: "10px", color: "black" }}>
                {success}
              </Typography>
            </>
          )}
          <Button
            onClick={error ? handleClose : handleLogin}
            variant="contained"
            sx={{
              marginTop: "20px",
              backgroundColor: "#4CAF50",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#45A049",
              },
            }}
          >
            {error ? "OK" : "Login"}
          </Button>
        </DialogContent>
      </Dialog>
      <Header />
      <Container maxWidth="sm">
        <Card sx={{ p: 4, margin: "2rem 0" }}>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "36px",
            }}
          >
            Forgot Password
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500PX",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Enter email Id
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                size="small"
                placeholder="Enter your mail id"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Button
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "20px",
                display: "block",
                background: "#39B54A",
                borderRadius: "40px",
                paddingRight: "22px",
                paddingLeft: "22px",
                color: "white",
                textTransform: "capitalize",
                "&:hover": {
                  textTransform: "capitalize",
                  color: "#333",
                  background: "#39B54A",
                  borderColor: "#39b54a",
                  fontWeight: 550,
                  borderRadius: "25px",
                },
              }}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Card>
      </Container>
      <Footer />
    </div>
  );
};

export default ForgetPassword;
