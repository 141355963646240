import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useMediaQuery } from "@mui/material";

const TermsConditions = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Header />
      <div
        style={{
          paddingLeft: isMobile ? "30PX" : "150px",
          paddingRight: isMobile ? "30PX" : "150px",
          font: "Inter",
          textAlign: "justify",
          fontSize: "18px",
        }}
      >
        <h1 style={{ font: "Inter", fontSize: "28px" }}>
          Terms and Conditions
        </h1>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          1. Introduction
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Welcome to www.educampustech.com (“we”, “our”, “us”) an online
          teaching and learning platform. By accessing or using our website (the
          "Platform"), you agree to be bound by these Terms and Conditions (the
          "Terms"). Please read these Terms carefully before using the Platform.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          2. Acceptance of Terms
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          By using the Platform, you agree to comply with and be legally bound
          by these Terms, whether or not you become a registered user of the
          services. If you do not agree to these Terms, you must not use the
          Platform.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          3. Changes to Terms
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          We reserve the right to modify these Terms at any time. We will notify
          you of any changes by posting the new Terms on the Platform. Your
          continued use of the Platform after such changes are posted
          constitutes your acceptance of the new Terms.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          4. User Registration
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          To access certain features of the Platform, you must register for an
          account. You agree to provide accurate, current, and complete
          information during the registration process and to update such
          information to keep it accurate, current, and complete. You are
          responsible for safeguarding your password and agree not to disclose
          your password to any third party.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          5. User Conduct
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          You agree to use the Platform only for lawful purposes. You agree not
          to use the Platform to:
        </p>
        <ul>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Post or transmit any content that is unlawful, harmful, threatening,
            abusive, harassing, defamatory, vulgar, obscene, libelous, invasive
            of another's privacy, or otherwise objectionable;
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Impersonate any person or entity, or falsely state or otherwise
            misrepresent your affiliation with a person or entity;
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Post or transmit any content that you do not have a right to make
            available under any law or under contractual or fiduciary
            relationships;
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Post or transmit any content that infringes any patent, trademark,
            trade secret, copyright, or other proprietary rights of any party;
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Post or transmit any unsolicited or unauthorized advertising,
            promotional materials, "junk mail," "spam," "chain letters,"
            "pyramid schemes," or any other form of solicitation;
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Post or transmit any material that contains software viruses or any
            other computer code, files, or programs designed to interrupt,
            destroy, or limit the functionality of any computer software or
            hardware or telecommunications equipment;
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Interfere with or disrupt the Platform or servers or networks
            connected to the Platform, or disobey any requirements, procedures,
            policies, or regulations of networks connected to the Platform.
          </li>
        </ul>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          6. Content Ownership
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          You retain ownership of any content you post on the Platform. However,
          by posting content to the Platform, you grant us a worldwide,
          non-exclusive, transferable, royalty-free license to use, reproduce,
          distribute, prepare derivative works of, display, and perform the
          content in connection with the Platform and our business.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          7. Payment and Refunds
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Certain services on the Platform may require payment. You agree to pay
          all fees and charges associated with your use of the services. We may
          change our fees and charges at any time, but we will provide you with
          advance notice of these changes.
        </p>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Refunds will be handled on a case-by-case basis and are at our sole
          discretion. If you believe you are entitled to a refund, please
          contact our customer service team.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          8. Termination
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          We reserve the right to terminate or suspend your account and access
          to the Platform at our sole discretion, without notice and liability,
          for conduct that we believe violates these Terms or is harmful to
          other users of the Platform, us, or third parties, or for any other
          reason.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          9. Disclaimer of Warranties
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          The Platform is provided on an "as is" and "as available" basis. We
          make no warranty that the Platform will meet your requirements or be
          available on an uninterrupted, secure, or error-free basis. We make no
          warranty regarding the quality of any products, services, or
          information obtained through the Platform.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          10. Limitation of Liability
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          To the maximum extent permitted by applicable law, we shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses, resulting from:
        </p>
        <ul>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Your use or inability to use the Platform;
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Any unauthorized access to or use of our servers and/or any personal
            information stored therein;
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Any interruption or cessation of transmission to or from the
            Platform;
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Any bugs, viruses, trojan horses, or the like that may be
            transmitted to or through our Platform by any third party;
          </li>
          <li
            style={{
              paddingLeft: "18px",
              font: "Inter",
              fontSize: "18px",
            }}
          >
            Any errors or omissions in any content or for any loss or damage
            incurred as a result of the use of any content posted, emailed,
            transmitted, or otherwise made available through the Platform.
          </li>
        </ul>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          11. Indemnification
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          You agree to indemnify, defend, and hold harmless
          www.educampustech.com, its affiliates, officers, directors, employees,
          agents, and licensors from and against any claims, liabilities,
          damages, losses, and expenses, including, without limitation,
          reasonable legal and accounting fees, arising out of or in any way
          connected with your access to or use of the Platform, or your
          violation of these Terms.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          12. Governing Law
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          These Terms shall be governed by and construed in accordance with the
          laws of India , without regard to its conflict of law principles.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          13. Dispute Resolution
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          Any dispute arising out of or relating to these Terms or the Platform
          shall be resolved through binding arbitration in accordance with the
          rules of the American Arbitration Association. The arbitration shall
          be conducted in Chennai, and judgment on the arbitration award may be
          entered into any court having jurisdiction thereof.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          14. Contact Information
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          If you have any questions about these Terms, please contact us at
          sachin.s@educampustech.in.
        </p>

        <h3
          style={{
            paddingLeft: "13px",
            fontFamily: "Inter",
            fontSize: "21px",
          }}
        >
          15. Entire Agreement
        </h3>
        <p
          style={{
            paddingLeft: "18px",
            font: "Inter",
            fontSize: "18px",
          }}
        >
          These Terms constitute the entire agreement between you and
          www.educampustech.com with respect to the Platform and supersede all
          prior or contemporaneous understandings and agreements, whether
          written or oral, with respect to the Platform.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
