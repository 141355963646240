import { Button, Card } from "@mui/material";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

function ConfirmMail() {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "2rem 0",
        }}
      >
        <Card
          sx={{
            padding: "2rem",
            textAlign: "center",
          }}
        >
          <h2>Please confirm your email</h2>
          <p>Thanks for signing up Educampus technology</p>
          <Button
            sx={{
              backgroundColor: "#39b54a",
              textTransform: "capitalize",
              fontWeight: 550,
              color: "white",
              borderColor: "#39b54a",
              borderRadius: "25px",
              "&:hover": {
                textTransform: "capitalize",
                color: "white",
                backgroundColor: "#39b54a",
                borderColor: "#39b54a",
                fontWeight: 550,
                borderRadius: "25px",
              },
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            Confirm your email
          </Button>
        </Card>
      </div>
      <Footer />
    </div>
  );
}

export default ConfirmMail;
