import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Modal,
  TextField,
  Typography,
  Box,
  IconButton,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import enroll from "../src/Images/enroll.jpg";
import useSupabase from "./utils/useSupabase";

const PopupForm = ({ open, handleClose, courseId }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    whatsapp: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const supabase = useSupabase();
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    validateForm();
  }, [formData, emailError]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "email") {
      validateEmail(value);
    }
  };

  const validateEmail = (email) => {
    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(email));
  };

  const validateForm = () => {
    const { name, email, whatsapp } = formData;
    setIsFormValid(
      name.trim() !== "" &&
        email.trim() !== "" &&
        whatsapp.trim() !== "" &&
        !emailError
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError) {
      return;
    }
    try {
      // Insert data into the 'enroll' table
      const { data, error } = await supabase.from("enrollCourses").insert([
        {
          name: formData.name,
          email: formData.email,
          whatsappNo: formData.whatsapp,
          courseId: localStorage.getItem("enrollCourseId"),
        },
      ]);
      if (error) {
        throw error;
      }
      console.log("Data inserted successfully:", data);
      handleClose();
    } catch (error) {
      console.error("Error inserting data:", error.message);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-modal-title"
      aria-describedby="form-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90%" : 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <IconButton onClick={handleClose} sx={{ float: "left" }}>
          <ArrowBackIcon />
        </IconButton>
        <Card sx={{ padding: 2, mt: isMobile ? 2 : 5 }}>
          <img
            src={enroll}
            alt="form-header"
            style={{
              width: "100%",
              height: isMobile ? "10vh" : "20vh",
              borderRadius: "8px",
            }}
          />
          <form onSubmit={handleSubmit}>
            <InputLabel sx={{ marginTop: 2 }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Name
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter your name"
              variant="outlined"
              required
              name="name"
              value={formData.name}
              onChange={handleChange}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^{a-z}{A-Z}]/g, "");
              }}
            />
            <InputLabel sx={{ marginTop: 2 }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Enter your Email id
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter your mail id"
              variant="outlined"
              required
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={emailError}
              helperText={
                emailError ? "Please enter a valid email address" : ""
              }
            />
            <InputLabel sx={{ marginTop: 2 }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                WhatsApp number
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter your current number"
              variant="outlined"
              required
              name="whatsapp"
              value={formData.whatsapp}
              onChange={handleChange}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
            />
            <Button
              variant="contained"
              color="success"
              type="submit"
              sx={{ mt: 3 }}
              disabled={!isFormValid}
            >
              Submit
            </Button>
          </form>
        </Card>
      </Box>
    </Modal>
  );
};

export default PopupForm;
