import React, { useEffect, useState } from "react";
import logo from "../Images/Group 63.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { ROUTINGDATA } from "./RoudingData";
import { useNavigate } from "react-router-dom";
import useSupabase from "../utils/useSupabase";
import "./HomePage.css"; // Import the CSS file
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { IconButton } from "@mui/material";

function Footer() {
  const [subcategory, setSubcategory] = useState([]);
  const navigate = useNavigate();
  const supabase = useSupabase();

  useEffect(() => {
    fetchSubcategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSubcategory = async () => {
    const { data } = await supabase
      .from("subcategory")
      .select()
      .eq("isActive", true)
      .limit(5)
      .order("id", { ascending: true });
    setSubcategory(data);
  };
  const goToInstagram = () => {
    window.open("https://www.instagram.com/educampustech/", "_blank");
  };
  const goToYoutube = () => {
    window.open("http://www.youtube.com/@EduCampusTechnologies", "_blank");
  };
  const goToFacebook = () => {
    window.open("https://www.facebook.com/educampustech", "_blank");
  };
  const goToXapp = () => {
    window.open("https://x.com/educampustech", "_blank");
  };
  const goToLinkd = () => {
    window.open(
      "https://www.linkedin.com/company/103070553/admin/feed/posts/",
      "_blank"
    );
  };
  return (
    <div className="footerMain">
      <div className="footerContent">
        <div className="footerSection">
          <h4>EDUCAMPUS</h4>
          <p
            onClick={() => {
              navigate(ROUTINGDATA.HOME);
              window.scrollTo(0, 0);
            }}
          >
            Home
          </p>
          <p
            onClick={() => {
              navigate(ROUTINGDATA.COURSE);
              window.scrollTo(0, 0);
            }}
          >
            Courses
          </p>
          <p
            onClick={() => {
              navigate(ROUTINGDATA.WORKSHOP);
              window.scrollTo(0, 0);
            }}
          >
            Workshops
          </p>
          <p
            onClick={() => {
              navigate(ROUTINGDATA.ABOUTUS);
              window.scrollTo(0, 0);
            }}
          >
            About us
          </p>
        </div>
        <div className="footerSection">
          <h4>Tech Courses</h4>
          {subcategory?.length > 0 ? (
            <ul>
              {subcategory.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    navigate(
                      `${ROUTINGDATA.CATEGORYPAGE}?name=${item.subcategoryCode}`
                    );
                    window.scrollTo(0, 0);
                  }}
                >
                  {item.subcategoryName}
                </li>
              ))}
            </ul>
          ) : (
            <p>No subcategories available</p>
          )}
        </div>
        <div className="footerSection">
          <h4>Policies</h4>
          <p
            onClick={() => {
              navigate(ROUTINGDATA.PRIVACY);
              window.scrollTo(0, 0);
            }}
          >
            Privacy Policy
          </p>
          <p
            onClick={() => {
              navigate(ROUTINGDATA.TERMSCONDITIONS);
              window.scrollTo(0, 0);
            }}
          >
            Terms & conditions
          </p>
          <p
            onClick={() => {
              navigate(ROUTINGDATA.PRICEPOLICY);
              window.scrollTo(0, 0);
            }}
          >
            Price Policy
          </p>
          <p
            onClick={() => {
              navigate(ROUTINGDATA.REFUNDPOLICY);
              window.scrollTo(0, 0);
            }}
          >
            Refund Policy
          </p>
        </div>
        <div className="footerSection">
          <h4 style={{ paddingLeft: "15px" }}>Contact us</h4>
          <div className="footerContact">
            <LocationOnIcon sx={{ mt: 2, mr: 1 }} />
            <p>
              13/33, Kaliamman Koil Street, Chinmaya Nagar, Virugambakkam,
              SBI/Canara Bank Building, Chennai - 600092
            </p>
          </div>
          <p>
            <CallIcon sx={{ mb: -1, mr: 0.5 }} /> +91 9940688891
          </p>
          <p>
            <EmailIcon sx={{ mb: -1, mr: 1 }} /> sachin.s@educampustech.in
          </p>
        </div>

        <div className="footerSection">
          <img
            className="FooterLogo"
            src={logo}
            alt="logo"
            onClick={() => {
              navigate(ROUTINGDATA.HOME);
              window.scrollTo(0, 0);
            }}
          />
          <h4
            style={{
              textAlign: "center",
              paddingBottom: "5px",
            }}
          >
            Follow us on
          </h4>
          <div className="footerSocial">
            <IconButton onClick={goToLinkd}>
              <LinkedInIcon sx={{ color: "white", fontSize: "30px" }} />
            </IconButton>
            <IconButton onClick={goToFacebook}>
              <FacebookOutlinedIcon sx={{ color: "white", fontSize: "30px" }} />
            </IconButton>
            <IconButton onClick={goToXapp}>
              <XIcon sx={{ color: "white", fontSize: "30px" }} />
            </IconButton>
            <IconButton onClick={goToYoutube}>
              <YouTubeIcon sx={{ color: "white", fontSize: "30px" }} />
            </IconButton>
            <IconButton onClick={goToInstagram}>
              <InstagramIcon sx={{ color: "white", fontSize: "30px" }} />
            </IconButton>
          </div>
        </div>
      </div>
      <h5
        style={{
          textAlign: "center",
          paddingBottom: "10px",
        }}
      >
        ©2024 EduCampus Technologies. All rights reserved.
      </h5>
    </div>
  );
}

export default Footer;
