import React, { useState } from "react";
import { Fragment } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import login from "../Images/login.png";
import { Link } from "react-router-dom";
import { ROUTINGDATA } from "./RoudingData";
import useSupabase from "../utils/useSupabase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const supabase = useSupabase();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (isLogin === true) {
      navigate("/");
    }
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid Email Id"
      )
      .required(" Email Id is required"),
    password: yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const email = values.email;
      const password = values.password;
      const { error: userError } = await supabase
        .from("userProfile")
        .select()
        .eq("email", email)
        .eq("userType", "Student")
        .single();

      if (userError) {
        handleClickOpen();
        setMessage("Please create an account first");
      } else {
        const { error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        if (error) {
          handleClickOpen();
          setMessage(error.message);
        } else {
          setMessage("Login successful");
          setIsLogin(true);
          handleClickOpen();
          localStorage.setItem("userId", email);
        }
      }
    },
  });

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          background: isMobile
            ? "none"
            : "linear-gradient(180deg, #EEFFF0 0%, #EAF2FF 100%)",
          padding: isMobile ? "0 10px" : "0",
        }}
      >
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent sx={{ width: "200px", p: 4, textAlign: "center" }}>
            {isLogin ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CheckCircleOutlineIcon
                  sx={{ fontSize: "70px", color: "#4CAF50" }}
                />
              </div>
            ) : null}
            <DialogContentText
              sx={{ fontSize: "20px", fontWeight: "bold" }}
              id="alert-dialog-slide-description"
            >
              {message}
            </DialogContentText>
            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "20px",
                  display: "block",
                  background: "#39B54A",
                  borderRadius: "40px",
                  paddingRight: "22px",
                  paddingLeft: "22px",
                  color: "white",
                  textTransform: "capitalize",
                  "&:hover": {
                    textTransform: "capitalize",
                    color: "#333",
                    background: "#39B54A",
                    borderColor: "#39b54a",
                    fontWeight: 550,
                    borderRadius: "25px",
                  },
                }}
                onClick={handleClose}
              >
                Ok
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <br />
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            width: "100%",
            height: isMobile ? "auto" : "700px",
            paddingLeft: isMobile ? "10px" : "0",
            paddingRight: isMobile ? "10px" : "0",
          }}
        >
          {!isMobile && (
            <div
              style={{
                flex: 1,
                marginLeft: "40px",
              }}
            >
              <Link to={ROUTINGDATA.HOME}>
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <br />
              <br />
              <br />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "30px",
                  fontWeight: "700",
                  lineHeight: "45px",
                  textAlign: "center",
                  background:
                    "linear-gradient(90.02deg, #39B54A 30.02%, #084FC7 58.52%, #0D4FBE 99.98%)",

                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                }}
              >
                WELCOME BACK
              </Typography>
              <img alt="login" src={login} style={{ paddingLeft: "50px" }} />
            </div>
          )}
          <div
            style={{
              flex: 1,
              marginRight: isMobile ? "0" : "40px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card
              sx={{
                width: isMobile ? "98%" : "680px",
                height: isMobile ? "auto" : "630px",
                top: isMobile ? "0" : "60px",
                gap: "0px",
                borderRadius: "20px",
                background: "#FFFFFF",
                padding: isMobile ? "20px" : "0",
                boxShadow: isMobile ? "none" : "default",
              }}
            >
              {isMobile && (
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "30px",
                    fontWeight: "700",
                    lineHeight: "45px",
                    textAlign: "center",
                    background:
                      "linear-gradient(90.02deg, #39B54A 30.02%, #084FC7 58.52%, #0D4FBE 99.98%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    paddingTop: "20px",
                  }}
                >
                  WELCOME BACK
                </Typography>
              )}
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "36px",
                  paddingTop: isMobile ? "25px" : "75px",
                }}
              >
                LOGIN
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <InputLabel
                  sx={{
                    paddingTop: "50px",
                    paddingLeft: isMobile ? "0" : "69px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "500PX",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "19.36px",
                    }}
                  >
                    Enter id
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <TextField
                  size="small"
                  name="email"
                  placeholder="Enter your mail id"
                  sx={{
                    paddingLeft: isMobile ? "0" : "69px",
                    width: isMobile ? "100%" : "554px",
                    borderRadius: "20px",
                    fontFamily: "Inter",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
                {formik.touched.email && formik.errors.email && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      paddingLeft: isMobile ? "0" : "69px",
                    }}
                  >
                    {formik.errors.email}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    paddingTop: "25px",
                    paddingLeft: isMobile ? "0" : "69px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "500PX",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "19.36px",
                    }}
                  >
                    Enter your password
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <TextField
                  size="small"
                  name="password"
                  placeholder="Enter your password"
                  type={showPassword ? "text" : "password"}
                  sx={{
                    paddingLeft: isMobile ? "0" : "69px",
                    width: isMobile ? "100%" : "554px",
                    borderRadius: "20px",
                    fontFamily: "Inter",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                />
                {formik.touched.password && formik.errors.password && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      paddingLeft: isMobile ? "0" : "69px",
                    }}
                  >
                    {formik.errors.password}
                  </Typography>
                )}
                <br />
                <Link
                  to={ROUTINGDATA.ForgetPassword}
                  style={{
                    paddingLeft: isMobile ? "190px" : "500px",
                    color: " #006EBE",
                  }}
                >
                  Forgot password?
                </Link>
                <Button
                  type="submit"
                  sx={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "20px",
                    display: "block",
                    background: "#39B54A",
                    borderRadius: "40px",
                    paddingRight: "22px",
                    paddingLeft: "22px",
                    color: "white",
                    textTransform: "capitalize",
                    "&:hover": {
                      textTransform: "capitalize",
                      color: "#333",
                      background: "#39B54A",
                      borderColor: "#39b54a",
                      fontWeight: 550,
                      borderRadius: "25px",
                    },
                  }}
                >
                  Log in
                </Button>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "16.94px",
                    textAlign: "center",
                    paddingTop: "7px",
                  }}
                >
                  New to Educampus?
                  <Link to={ROUTINGDATA.SIGNUP} style={{ color: " #006EBE" }}>
                     Register Here{" "}
                  </Link>
                </Typography>
                <br />
                <br />
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "16.94px",
                    textAlign: "center",
                    paddingTop: "7px",
                    paddingRight: isMobile ? "2px" : "0px",
                    paddingLeft: isMobile ? "2px" : "0px",
                  }}
                >
                  By continuing, you agree to  Educampus
                  <Link
                    to={ROUTINGDATA.TERMSCONDITIONS}
                    style={{ color: " #006EBE" }}
                  >
                    Terms Of Use{" "}
                  </Link>{" "}
                  and{" "}
                  <Link to={ROUTINGDATA.PRIVACY} style={{ color: " #006EBE" }}>
                    Privacy Policy
                  </Link>
                </Typography>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
