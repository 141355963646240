import React, { useEffect, useState } from "react";
import Header from "./Header";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { Card, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import ChooseUs from "./ChooseUs";
import Footer from "./Footer";
import useSupabase from "../utils/useSupabase";
import { useSearchParams } from "react-router-dom";
import nocourse from "../Images/nocourse.png";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "./RoudingData";
const CategoryPage = () => {
  const supabase = useSupabase();
  const [course, setCourse] = useState([]);
  const [courseCat, setCourseCat] = useState([]);
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const coursename = searchParams.get("name");
  const isMobile = useMediaQuery("(max-width:600px)");
  const fetchCourse = async () => {
    const { data: courseData } = await supabase
      .from("course")
      .select()
      .eq("courseSubCategory", coursename)
      .eq("isActive", true)
      // .limit(4);
    setCourse(courseData);

    const { data: subcategoryData } = await supabase
      .from("subcategory")
      .select("subcategoryName")
      .eq("subcategoryCode", coursename);
    setCourseCat(subcategoryData);
  };

  useEffect(() => {
    fetchCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursename]);

  const handleSearch = () => {
    // Handle search logic here
    console.log("Search button clicked");
  };

  return (
    <div>
      <Header />
      <Grid
        xs={5}
        style={{
          paddingRight: "5rem",
          paddingLeft: "2rem",
          paddingTop: "3rem",
        }}
      >
        <div>
          <TextField
            size="small"
            sx={{
              width: { md: "400px" },
              "& .MuiOutlinedInput-root": {
                borderRadius: "25px",
              },
            }}
            placeholder="Search"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <IconButton onClick={handleSearch} aria-label="search">
                  <SearchIcon />
                </IconButton>
              ),
              endAdornment: (
                <Button
                  variant="contained"
                  onClick={handleSearch}
                  sx={{
                    backgroundColor: "#39b54a",
                    textTransform: "capitalize",
                    fontWeight: 550,
                    color: "white",
                    padding: "2px 20px",
                    borderColor: "#39b54a",
                    borderRadius: "25px",
                    "&:hover": {
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: "#39b54a",
                      borderColor: "#39b54a",
                      fontWeight: 550,
                      borderRadius: "25px",
                    },
                  }}
                >
                  Search
                </Button>
              ),
            }}
          />
        </div>
      </Grid>
      {courseCat.map((data) => (
        <h2 style={{ marginLeft: "35px" }}>{data.subcategoryName} </h2>
      ))}
      <Grid container style={{ marginLeft: isMobile ? "0px" : "30px" }}>
        {course && course.length > 0 ? (
          course.map((data) => (
            <Grid
              key={data.id}
              xs={12}
              sm={6}
              md={3}
              sx={{ p: 2, cursor: "pointer" }}
            >
              <Card
                className="CourseCard"
                style={{
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  borderRadius: "15px",
                }}
                onClick={() => {
                  navigate(`${ROUTINGDATA.DETAILSPAGE}?id=${data.id}`);
                  window.scrollTo(0, 0);
                }}
              >
                <img
                  src={data.imageUrl}
                  alt="courseImage"
                  style={{ height: "150px", width: "100%" }}
                />
                <div
                  className="courseCard"
                  style={{
                    padding: "1rem",
                    color: "#505050",
                    fontWeight: 600,
                  }}
                >
                  <p
                    style={{
                      color: "#303030",
                      fontSize: isMobile ? "16px" : "20px",
                      fontWeight: 600,
                      margin: 0,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "270px",
                    }}
                  >
                    {data.courseName}
                  </p>
                  <div style={{ display: "flex", margin: "-7px 0px" }}>
                    <AccessTimeIcon sx={{ mt: 1.8, pr: 0.4 }} />
                    <p> {data.duration} hours</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "-1.5rem",
                      marginBottom: "-1.4rem",
                    }}
                  >
                    <SignalCellularAltIcon sx={{ mt: 1.8, pr: 0.4 }} />
                    <p> {data.lable} Level</p>
                  </div>
                  <p>By {data.trainer} </p>
                  <div style={{ display: "flex", marginTop: "-1.3rem" }}>
                    <p>Skills</p>
                    <div style={{ position: "relative" }}>
                      <ErrorRoundedIcon
                        sx={{
                          mt: 1.8,
                          pl: 0.4,
                          color: "black",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setHoveredCardId(data.id)}
                        onMouseLeave={() => setHoveredCardId(null)}
                      />
                      {hoveredCardId === data.id && (
                        <div className="popup">
                          <ul>
                            {data.skills.split(",").map((skill, index) => (
                              <li key={index}>{skill.trim()}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <Divider sx={{ border: "1px solid gray" }} />
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "-0.5rem",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <del style={{ marginTop: "0.1rem" }}>
                        ₹{data.realfees}
                      </del>
                      <p
                        style={{
                          margin: 0,
                          paddingLeft: "10px",
                          color: "black",
                          fontSize: "18px",
                        }}
                      >
                        ₹ {data.fees}
                      </p>
                    </div>
                    <Button
                      variant="contained"
                      sx={{
                        //mt: "-0.6rem",
                        pt: "4px",
                        backgroundColor: "#39b54a",
                        textTransform: "capitalize",
                        fontWeight: 550,
                        color: "white",
                        borderColor: "#39b54a",
                        borderRadius: "25px",
                        "&:hover": {
                          textTransform: "capitalize",
                          color: "white",
                          backgroundColor: "#39b54a",
                          borderColor: "#39b54a",
                          fontWeight: 550,
                          borderRadius: "25px",
                        },
                      }}
                    >
                      Know More
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            item
            xs={12}
            style={{
              minHeight: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "20px",
                color: "#505050",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={nocourse}
                alt="nocourse"
                style={{ height: isMobile ? "250px" : "430px" }}
              />
              <Typography
                sx={{
                  font: "Inter",
                  marginTop: { md: "-50px" },
                  paddingLeft: "30px",
                  fontSize: {
                    xs: "20px", // Font size for extra small screens
                    sm: "30px", // Font size for small screens
                    md: "35px", // Default font size for medium screens and up
                  },
                }}
              >
                No Courses Available....
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
      <br />
      <br />
      <ChooseUs />
      <br />
      <Footer />
    </div>
  );
};

export default CategoryPage;
