// import React from "react";
// import certification1 from "../Images/certification1.png";
// import certification2 from "../Images/certification2.png";
// import certification3 from "../Images/certification3.png";
// import certification4 from "../Images/certification4.png";
// import certification5 from "../Images/certification5.png";
// import certification6 from "../Images/certification6.png";
// import certification7 from "../Images/certification7.png";
// import certification8 from "../Images/certification8.png";
// import certification9 from "../Images/certification9.png";
// import certification10 from "../Images/certification10.png";
// import certification11 from "../Images/certification11.png";
// import Marquee from "react-fast-marquee";
// import "./HomePage.css";
// const TrainingCertification = () => {
//   return (
//     <>
//       <h2 style={{ textAlign: "center" }}>Certification Training</h2>
//       <Marquee pauseOnHover gradient className="marqueeborder">
//         <div className="maequeepartnerimg">
//           <img src={certification1} alt="" />
//         </div>
//         <div className="maequeepartnerimg">
//           <img src={certification2} alt="" />
//         </div>
//         <div className="maequeepartnerimg">
//           <img src={certification3} alt="" />
//         </div>
//         <div className="maequeepartnerimg">
//           <img src={certification4} alt="" />
//         </div>
//         <div className="maequeepartnerimg">
//           <img src={certification5} alt="" />
//         </div>
//         <div className="maequeepartnerimg">
//           <img src={certification6} alt="" />
//         </div>
//         <div className="maequeepartnerimg">
//           <img src={certification7} alt="" />
//         </div>
//         <div className="maequeepartnerimg">
//           <img src={certification8} alt="" />
//         </div>
//         <div className="maequeepartnerimg">
//           <img src={certification9} alt="" />
//         </div>
//         <div className="maequeepartnerimg">
//           <img src={certification10} alt="" />
//         </div>
//         <div className="maequeepartnerimg">
//           <img src={certification11} alt="" />
//         </div>
//       </Marquee>
//     </>
//   );
// };

// export default TrainingCertification;

import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import useSupabase from "../utils/useSupabase";
import "./HomePage.css";

const TrainingCertification = () => {
  const [certificate, setCertificate] = useState();
  const supabase = useSupabase();
  useEffect(
    () => {
      fetchCertificate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const fetchCertificate = async () => {
    const { data } = await supabase
      .from("certification")
      .select("imageUrl")
      .eq("isActive", true);

    setCertificate(data);
  };
  return (
    <>
      <h2 style={{ textAlign: "center" }}>Certification Training</h2>
      <Marquee pauseOnHover gradient className="marqueeborder">
        {certificate?.map((certificate, index) => (
          <div className="maequeepartnerimg">
            <img src={certificate.imageUrl} alt="" />
          </div>
        ))}
      </Marquee>
    </>
  );
};

export default TrainingCertification;
