import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import useSupabase from "../utils/useSupabase";
import { useMediaQuery } from "@mui/material";
import { ROUTINGDATA } from "./RoudingData";
import Footer from "./Footer";

const Blog = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const supabase = useSupabase();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const { data, error } = await supabase
        .from("blog")
        .select("id, name, imageUrl, shortDesc")
        .eq("isActive", true);

      if (error) {
        console.error("Error fetching blogs:", error);
      } else {
        setBlogs(data);
      }
    };

    fetchBlogs();
  }, [supabase]);

  return (
    <>
      <Header />
      <div style={{ height: "100vh", backgroundColor: "#EFFFF1" }}>
        <div
          style={{
            padding: "20px",
            display: "grid",
            gridTemplateColumns: isMobile ? "1fr" : "repeat(3, 1fr)",
            gap: "20px",
          }}
        >
          {blogs.map((blog) => (
            <div
              key={blog.id}
              style={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "#fff",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <img
                src={blog.imageUrl}
                alt={blog.name}
                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              />
              <h3>{blog.name}</h3>
              <p>{blog.shortDesc}</p>
              <Link
                to={`${ROUTINGDATA.BLOGDETAILS}/${blog.id}`}
                style={{ color: "green" }}
              >
                Read More →
              </Link>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Blog;
