import React, { useEffect, useState } from "react";
import "./HomePage.css";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CourseImage from "../Images/ets semi circle.png";
import BoyImage from "../Images/ets boy.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useSupabase from "../utils/useSupabase";
import { Link, useLocation } from "react-router-dom";
import { ROUTINGDATA } from "./RoudingData";
import { useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText, Paper } from "@mui/material";

function ContentPage() {
  const navigate = useNavigate();
  const supabase = useSupabase();
  const [banner, setBanner] = useState();
  useEffect(
    () => {
      fetchBanner();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchBanner = async () => {
    const { data } = await supabase
      .from("banner")
      .select()
      .eq("isActive", true);

    setBanner(data);
  };

  const [course, setCourse] = useState([]);
  const [filteredCourse, setFilteredCourse] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const location = useLocation();

  useEffect(
    () => {
      fetchCourse();
      fetchSubcategory();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchCourse = async () => {
    const { data } = await supabase
      .from("course")
      .select()
      .eq("isActive", true);
    setCourse([...data]);
  };

  const fetchSubcategory = async () => {
    const { data } = await supabase
      .from("subcategory")
      .select()
      .eq("isActive", true);
    setSubcategory(data);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = () => {
    const filteredCourses = course.filter(
      (course) =>
        course.courseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.trainer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.skills.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSuggestions(filteredCourses);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value) {
      const filteredCourses = course.filter(
        (course) =>
          course.courseName.toLowerCase().includes(value.toLowerCase()) ||
          course.trainer.toLowerCase().includes(value.toLowerCase()) ||
          course.skills.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredCourses);
    } else {
      setSuggestions([]);
    }
  };

  return (
    <div>
      <Grid container style={{ paddingInline: "40px" }}>
        <Grid xs={12} md={5} className="content1">
          <h1 className="mainTitle" style={{ fontFamily: "K2D-Bold" }}>
            Investing in <span style={{ color: "#39B54A" }}>knowledge </span>and
            Your <span style={{ color: "#39B54A" }}>future </span>
            through{" "}
            <span className="educampus-container">
              <span style={{ color: "#084FC7" }}> Educampus</span>
            </span>
          </h1>
          <p
            style={{
              color: "#3B475B",
              textAlign: "justify",
              font: "Inter",
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "28px",
            }}
            className="mainContent"
          >
            Explore an extensive collection of courses crafted to ignite
            curiosity and fuel achievement, empowering you to excel through
            immersive, interactive learning encounters. With Educampus, you'll
            gain the skills and knowledge needed to thrive in an ever-changing
            world.
          </p>
          <div className="search">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2rem",
                position: "relative",
              }}
            >
              <TextField
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "25px",
                  },
                }}
                placeholder="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <IconButton onClick={handleSearch} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  ),
                  endAdornment: (
                    <Button
                      variant="contained"
                      onClick={handleSearch}
                      sx={{
                        backgroundColor: "#39b54a",
                        textTransform: "capitalize",
                        fontWeight: 550,
                        color: "white",
                        padding: "2px 20px",
                        borderColor: "#39b54a",
                        borderRadius: "25px",
                        "&:hover": {
                          textTransform: "capitalize",
                          color: "white",
                          backgroundColor: "#39b54a",
                          borderColor: "#39b54a",
                          fontWeight: 550,
                          borderRadius: "25px",
                        },
                      }}
                    >
                      Search
                    </Button>
                  ),
                }}
              />
              {searchTerm && (
                <Paper
                  sx={{
                    position: "absolute",
                    top: "50px",
                    width: "100%",
                    maxWidth: "350px",
                    maxHeight: "300px",
                    marginTop: "1rem",
                    overflowY: "scroll",
                    zIndex: 1,
                    mt: -1.3,
                  }}
                >
                  {suggestions.length > 0 ? (
                    <List>
                      {suggestions.map((course) => (
                        <ListItem
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(ROUTINGDATA.COURSE, {
                              state: { id: course.id },
                            });
                          }}
                          key={course.id}
                        >
                          <ListItemText
                            primary={course.courseName}
                            secondary={`Trainer: ${course.trainer} | Skills: ${course.skills}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <List>
                      <ListItem>
                        <ListItemText primary="Search list not found" />
                      </ListItem>
                    </List>
                  )}
                </Paper>
              )}
            </div>
          </div>
        </Grid>
        <Grid
          xs={12}
          md={7}
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Carousel
            showArrows={false}
            autoPlay={true}
            interval={3000}
            infiniteLoop={true}
            showThumbs={false}
          >
            {banner?.map((banner, i) => (
              <img
                src={banner.imageUrl}
                className="courseImage"
                alt="courseImage"
              />
            ))}
          </Carousel>
        </Grid>
      </Grid>
      <div
        style={{ backgroundColor: "rgba(57,181,74, 0.7)", marginTop: "3rem" }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            className="content2"
            md={5.4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img className="content2" src={CourseImage} alt="CourseImage" />
          </Grid>
          <Grid className="content2" item md={2}>
            <img
              className="content2"
              src={BoyImage}
              alt="BoyImage"
              style={{ marginTop: "-0.5rem" }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="content2JoinUs">
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "white",
                  fontFamily: "Source Serif Pro",
                }}
              >
                Join Our{" "}
                <span
                  className="educampus-container1"
                  style={{ color: "#084FC7" }}
                >
                  {" "}
                  Courses
                </span>
              </p>
              <p style={{ fontWeight: 500, color: "white" }}>
                Learn from industry experts and seasoned professionals who are
                passionate about sharing their knowledge and experience with
                you.
              </p>
              <Link
                to={ROUTINGDATA.COURSE}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                style={{ color: "black" }}
              >
                Get started <ArrowRightAltIcon sx={{ mb: -1 }} />
              </Link>
            </div>
            <Grid
              className="mobile-content2"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                className="mobile-content2"
                src={CourseImage}
                alt="CourseImage"
                style={{ width: "60%", marginTop: "1.5rem" }}
              />
              <img
                className="mobile-content2"
                src={BoyImage}
                alt="BoyImage"
                style={{ width: "45%", marginTop: "-5rem" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ContentPage;
