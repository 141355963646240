import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import contact from "../Images/contact.png";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useFormik } from "formik";
import * as yup from "yup";
import Header from "./Header";
import useSupabase from "../utils/useSupabase";
import Footer from "./Footer";

const ContactUs = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const supabase = useSupabase();
  const [course, setCourse] = useState();

  const [open, setOpen] = useState(false); // For handling the dialog
  const [loading, setLoading] = useState(false);
  const initialValues = {
    name: "",
    course: "",
    mail: "",
    number: "",
    message: "",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    course: yup.string().required("Course is required"),
    mail: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid Email Id"
      )
      .required(" Email Id is required"),
    number: yup
      .string()
      .matches(/^\d{10}$/, "Phone No. must be exactly 10 digits")
      .required("Phone No. is required"),
    message: yup.string().required("Message is required"),
  });

  useEffect(() => {
    fetchCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchCourse = async () => {
    const { data } = await supabase
      .from("course")
      .select()
      .eq("isActive", true);
    setCourse(data);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
  });
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setLoading(true);
    const { name, course, mail, number, message } = formik.values;

    const emailContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Contact Us Form Submission</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                background-color: #f4f4f4;
            }
            .container {
                width: 100%;
                max-width: 600px;
                margin: 20px auto;
                background-color: #fff;
                padding: 20px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            h3 {
                color: #333;
            }
            p {
                color: #555;
                line-height: 1.6;
            }
            .strong {
                font-weight: bold;
                color: #333;
            }
            .footer {
                margin-top: 20px;
                font-size: 0.9em;
                color: #999;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <h3>New Contact Us Form Submission</h3>
            <p>Hello Sachithanantham Santhosam,</p>
            <p>You have received a new submission from the Contact Us form EduCampus Technologies. Here are the details:</p>
            <p><span class="strong">Name:</span> ${name}</p>
            <p><span class="strong">Course:</span> ${course}</p>
            <p><span class="strong">Email:</span> ${mail}</p>
            <p><span class="strong">Phone No.:</span> ${number}</p>
            <p><span class="strong">Message:</span></p>
            <p>${message}</p>
            <p class="footer">This email was sent automatically from the Contact Us form on your website. Please do not reply directly to this email.</p>
            <br/>
            Thank you for your attention.
            <br/>
            <br/>
            Best regards, <br/>
            Team EduCampus Technologies
            </div>
    </body>
    </html>
  `;

    try {
      const response = await fetch(
        "https://usgs-mail-server.onrender.com/usgs-services/sendmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            html: emailContent,
            to: "sachin.s@educampustech.in",
            subject: "Contact Us Form Submission",
          }),
        }
      );

      if (response.ok) {
        formik.resetForm();
        setOpen(true); // Open the dialog
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setLoading(false); // Set loading to false after the submission is done
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h4"
          style={{
            padding: "20px",
            textAlign: "center",
            font: "Inter",
            fontSize: "26px",
            fontWeight: "700",
            lineHeight: "36.74px",
          }}
        >
          Contact us
        </Typography>
        <Typography
          sx={{
            font: "Inter",
            fontSize: "22px",
            fontWeight: "500",
            lineHeight: "35.42px",
            textAlign: "center",
            paddingRight: isMobile ? "10px" : "0px",
            paddingLeft: isMobile ? "10px" : "0px",
          }}
        >
          We're here to help! Whenever you have a question or need assistance
          with anything related to our website, or just want to say hello, feel
          free to reach out to us using any of the methods below.
        </Typography>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          {!isMobile && (
            <div
              style={{
                flex: "1",
                marginRight: "20px",
                backgroundColor: "#DBF7FF",
              }}
            >
              <img
                src={contact}
                alt="contactUs"
                style={{ paddingLeft: "25PX" }}
              />
            </div>
          )}
          <div
            style={{
              flex: "1",
              paddingLeft: isMobile ? "20PX" : "50px",
              paddingRight: isMobile ? "20px" : "0px",
            }}
          >
            <Typography
              sx={{
                font: "Inter",
                fontSize: "22.67px",
                fontWeight: "600",
                lineHeight: "27.43px",
                color: "#0E2F71",
              }}
            >
              Send your queries
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <InputLabel sx={{ paddingTop: "20px" }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500PX",
                    font: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  Full Name
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                sx={{
                  width: isMobile ? "100%" : "554px",
                  borderRadius: "20px",
                  font: "Inter",
                }}
                size="small"
                name="name"
                placeholder="Enter Your Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
              {formik.touched.name && formik.errors.name && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                  }}
                >
                  {formik.errors.name}
                </Typography>
              )}
              <InputLabel sx={{ paddingTop: "20px" }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500PX",
                    font: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  Course
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  name="course"
                  value={formik.values.course}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.course && Boolean(formik.errors.course)}
                  sx={{ width: isMobile ? "100%" : "98%", height: 40 }}
                >
                  {course?.map((course) => (
                    <MenuItem key={course.id} value={course.courseCode}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {formik.touched.course && formik.errors.course && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                  }}
                >
                  {formik.errors.course}
                </Typography>
              )}
              <InputLabel sx={{ paddingTop: "20px" }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500PX",
                    font: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  Mail
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                sx={{
                  width: isMobile ? "100%" : "554px",
                  borderRadius: "20px",
                  font: "Inter",
                }}
                size="small"
                name="mail"
                placeholder="Enter Your Mail"
                value={formik.values.mail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.mail && Boolean(formik.errors.mail)}
              />
              {formik.touched.mail && formik.errors.mail && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                  }}
                >
                  {formik.errors.mail}
                </Typography>
              )}
              <InputLabel sx={{ paddingTop: "20px" }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500PX",
                    font: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  {" "}
                  Phone No.
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                sx={{
                  width: isMobile ? "100%" : "554px",
                  borderRadius: "20px",
                  font: "Inter",
                }}
                size="small"
                name="number"
                placeholder="Enter Your Number"
                value={formik.values.number}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.length <= 10) {
                    formik.handleChange(e);
                  }
                }}
                onKeyPress={(e) => {
                  const allowedKeys = /[0-9\b]/;
                  if (!allowedKeys.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.number && Boolean(formik.errors.number)}
              />
              {formik.touched.number && formik.errors.number && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                  }}
                >
                  {formik.errors.number}
                </Typography>
              )}
              <InputLabel sx={{ paddingTop: "20px" }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500PX",
                    font: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  Your Message
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                sx={{
                  width: isMobile ? "100%" : "554px",
                  borderRadius: "20px",
                  font: "Inter",
                }}
                size="small"
                name="message"
                placeholder="Enter Your Message"
                multiline
                rows={2}
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.message && Boolean(formik.errors.message)}
              />
              {formik.touched.message && formik.errors.message && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                  }}
                >
                  {formik.errors.message}
                </Typography>
              )}
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={!(formik.isValid && formik.dirty) || loading}
                sx={{
                  marginLeft: "auto",
                  fontSize: "17px",
                  marginTop: "20px",
                  display: "block",
                  background: "#39B54A",
                  borderRadius: "40px",
                  paddingRight: "22px",
                  paddingLeft: "22px",
                  color: "white",
                  textTransform: "capitalize",
                  "&:hover": {
                    textTransform: "capitalize",
                    color: "#333",
                    background: "#39B54A",
                    borderColor: "#39b54a",
                    fontWeight: 550,
                    borderRadius: "25px",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} size={24} />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent
                sx={{
                  textAlign: "center",
                  padding: "40px 20px",
                  color: "#fff",
                  borderRadius: "8px",
                }}
              >
                <CheckCircleOutlineIcon
                  sx={{ fontSize: "70px", color: "#4CAF50" }}
                />
                <DialogTitle
                  sx={{ fontSize: "24px", fontWeight: "bold", color: "black" }}
                >
                  Thank You!
                </DialogTitle>
                <Typography sx={{ marginTop: "10px", color: "black" }}>
                  Your details have been successfully submitted. Thanks!
                </Typography>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  sx={{
                    marginTop: "20px",
                    backgroundColor: "#4CAF50",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#45A049",
                    },
                  }}
                >
                  OK
                </Button>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div
        style={{
          width: isMobile ? "100%" : "99.5%",
          height: isMobile ? "auto" : "auto",
          backgroundColor: "#F0FFF2",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: isMobile ? "0px" : "0",
        }}
      >
        <Typography
          sx={{
            font: "Inter",
            fontSize: isMobile ? "20px" : "26px",
            fontWeight: "700",
            lineHeight: isMobile ? "28px" : "36.74px",
            textAlign: "center",
          }}
        >
          Support Center
        </Typography>
        <Typography
          sx={{
            font: "Inter",
            fontSize: isMobile ? "16px" : "20px",
            fontWeight: "400",
            lineHeight: isMobile ? "22px" : "28px",
            textAlign: "center",
            color: "#4B4B4B",
            marginTop: isMobile ? "10px" : "10px",
            paddingLeft: isMobile ? "10px" : "0",
            paddingRight: isMobile ? "10px" : "0",
          }}
        >
          Our dedicated support team is available to assist you with any
          technical issues, account inquiries, or course-related questions.
          Contact us through
        </Typography>
        <Card
          sx={{
            width: isMobile ? "90%" : "680px",
            height: "auto",
            borderRadius: "10px",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: isMobile ? "20px" : "20px",
            padding: isMobile ? "20px" : "5px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              padding: isMobile ? "10px 0" : "10px 0",
            }}
          >
            <MailOutlineOutlinedIcon
              sx={{ fontSize: "42px", color: "#09941C", marginBottom: "10px" }}
            />
            <Typography
              sx={{
                font: "Poppins",
                fontSize: isMobile ? "18px" : "24.06px",
                fontWeight: "400",
                lineHeight: isMobile ? "26px" : "36.09px",
                letterSpacing: "0.06em",
              }}
            >
              sachin.s@educampustech.in
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              padding: isMobile ? "10px 0" : "10px 0",
            }}
          >
            <CallOutlinedIcon
              sx={{ fontSize: "42px", color: "#09941C", marginBottom: "10px" }}
            />
            <Typography
              sx={{
                font: "Poppins",
                fontSize: isMobile ? "18px" : "24.06px",
                fontWeight: "400",
                lineHeight: isMobile ? "26px" : "36.09px",
                letterSpacing: "0.06em",
              }}
            >
              +91 9940688891
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              padding: isMobile ? "10px 0" : "0px 0",
            }}
          >
            <FmdGoodOutlinedIcon
              sx={{ fontSize: "42px", color: "#09941C", marginBottom: "10px" }}
            />
            <Typography
              sx={{
                font: "Poppins",
                fontSize: isMobile ? "18px" : "24.06px",
                fontWeight: "400",
                lineHeight: isMobile ? "26px" : "36.09px",
                letterSpacing: "0.06em",
              }}
            >
              13/33, Kaliamman Koil Street, Chinmaya Nagar, Virugambakkam,
              SBI/Canara Bank Building, Chennai - 600092
            </Typography>
          </div>
        </Card>
      </div>
      <br />
      <Footer />
    </Fragment>
  );
};

export default ContactUs;
