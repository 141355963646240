import { Card, Divider, Grid, Modal, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import useSupabase from "../utils/useSupabase";
import { Link, useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "./RoudingData";
import PopupForm from "../PopupForm";

function ExploreCourse() {
  const supabase = useSupabase();
  const [course, setCourse] = useState([]);
  const navigate = useNavigate();
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");

  // State for managing the popup form
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    fetchCourse();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCourse = async () => {
    const { data } = await supabase
      .from("course")
      .select()
      .eq("isActive", true)
      .limit(4);
    setCourse(data);
  };

  // const handleOpenPopup = () => setOpenPopup(true);
  const handleClosePopup = () => setOpenPopup(false);

  // const handleEnrollClick = (id) => {
  //   localStorage.setItem("enrollCourseId", id);
  //   handleOpenPopup();
  // };

  return (
    <div
      style={{
        padding: "40px",
        paddingTop: "15px",
        backgroundColor: "#f2f6ff",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          paddingBottom: "5px",
        }}
      >
        <h2 style={{ textAlign: "center", margin: "0", flexGrow: "1" }}>
          Explore Courses
        </h2>
        <Link
          to={ROUTINGDATA.COURSE}
          style={{
            position: isMobile ? "static" : "absolute",
            right: "0",
            color: "green",
            marginTop: isMobile ? "10px" : "0",
          }}
        >
          View All
        </Link>
      </div>
      <br />
      <Grid container spacing={2}>
        {course &&
          course.map((data) => (
            <Grid
              key={data.id}
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ cursor: "pointer" }}
            >
              <Card
                className="CourseCard"
                sx={{
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  "&:hover": {
                    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
                  },
                  borderRadius: "15px",
                }}
                onClick={() => {
                  navigate(`${ROUTINGDATA.DETAILSPAGE}?id=${data.id}`);
                  window.scrollTo(0, 0);
                }}
              >
                <img
                  src={data.imageUrl}
                  alt="courseImage"
                  style={{ height: "150px", width: "100%" }}
                />
                <div
                  className="courseCard"
                  style={{
                    padding: isMobile ? "0.5rem" : "1rem",
                    color: "#505050",
                    fontWeight: 600,
                  }}
                >
                  <p
                    style={{
                      color: "#303030",
                      fontSize: isMobile ? "16px" : "20px",
                      fontWeight: 600,
                      margin: 0,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "270px",
                    }}
                  >
                    {data.courseName}
                  </p>

                  <div style={{ display: "flex", margin: "-7px 0px" }}>
                    <AccessTimeIcon sx={{ mt: 1.8, pr: 0.4 }} />
                    <p>{data.duration} hours</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "-1.5rem",
                      marginBottom: "-1.4rem",
                    }}
                  >
                    <SignalCellularAltIcon sx={{ mt: 1.8, pr: 0.4 }} />
                    <p>{data.lable} Level</p>
                  </div>
                  <div>
                    <p>By {data.trainer}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "-1.3rem",
                      position: "relative",
                    }}
                  >
                    <p>Skills</p>
                    <div style={{ position: "relative" }}>
                      <ErrorRoundedIcon
                        sx={{
                          mt: 1.8,
                          pl: 0.4,
                          color: "black",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setHoveredCardId(data.id)}
                        onMouseLeave={() => setHoveredCardId(null)}
                      />
                      {hoveredCardId === data.id && (
                        <div className="popup">
                          <ul>
                            {data.skills.split(",").map((skill, index) => (
                              <li key={index}>{skill.trim()}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <Divider sx={{ border: "1px solid gray" }} />
                  <br />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "-0.5rem",
                      bottom: 0,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <del style={{ marginTop: "0.1rem" }}>
                        ₹{data.realfees}
                      </del>
                      <p
                        style={{
                          margin: 0,
                          paddingLeft: "10px",
                          color: "black",
                          fontSize: "18px",
                        }}
                      >
                        ₹{data.fees}
                      </p>
                    </div>
                    <Button
                      variant="contained"
                      sx={{
                        // mt: "-0.6rem",
                        pt: "4px",
                        backgroundColor: "#39b54a",
                        textTransform: "capitalize",
                        fontWeight: 550,
                        color: "white",
                        borderColor: "#39b54a",
                        borderRadius: "25px",
                        "&:hover": {
                          textTransform: "capitalize",
                          color: "white",
                          backgroundColor: "#39b54a",
                          borderColor: "#39b54a",
                          fontWeight: 550,
                          borderRadius: "25px",
                        },
                      }}
                      // onClick={(e) => {
                      //   e.stopPropagation(); // Prevent card click
                      //   handleEnrollClick(data.id);
                      // }}
                    >
                      Know More
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid>
          ))}
      </Grid>
      <Modal
        open={openPopup}
        onClose={handleClosePopup}
        aria-labelledby="form-modal-title"
        aria-describedby="form-modal-description"
      >
        <PopupForm open={openPopup} handleClose={handleClosePopup} />
      </Modal>
    </div>
  );
}

export default ExploreCourse;
