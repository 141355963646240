import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import useSupabase from "../utils/useSupabase";
import { useMediaQuery } from "@mui/material";

const BlogDetails = () => {
  const { id } = useParams();
  const supabase = useSupabase();
  const [blog, setBlog] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    const fetchBlogDetails = async () => {
      const { data, error } = await supabase
        .from("blog")
        .select("*")
        .eq("id", id)
        .single(); // This will fetch a single object instead of an array

      if (error) {
        console.error("Error fetching blog details:", error);
      } else {
        setBlog(data);
        console.log(data);
      }
    };

    fetchBlogDetails();
  }, [id, supabase]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <div style={{ padding: "20px", backgroundColor: "#EFFFF1" }}>
        <h1 style={{ color: "black" }}>{blog.name}</h1>
        {isMobile && (
          <img
            src={blog.imageUrl}
            alt={blog.name}
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            style={{
              font: "Inter",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "30px",
              color: "#2D2D2D",
            }}
          >
            {blog.desc1}
          </p>
          {!isMobile && (
            <img
              src={blog.imageUrl}
              alt={blog.name}
              style={{ width: "200%", height: "auto", borderRadius: "8px" }}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              font: "Inter",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "30px",
              color: "#2D2D2D",
            }}
          >
            {blog.desc2}
          </p>
          <p
            style={{
              font: "Inter",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "30px",
              color: "#2D2D2D",
            }}
          >
            {blog.desc3}
          </p>
          <p
            style={{
              font: "Inter",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "30px",
              color: "#2D2D2D",
            }}
          >
            {blog.desc4}
          </p>
          <p
            style={{
              font: "Inter",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "30px",
              color: "#2D2D2D",
            }}
          >
            {blog.desc5}
          </p>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
