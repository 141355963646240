import React from "react";
import "./HomePage.css";
import box1img from "../Images/img-1-aboutus.png";
import box2img from "../Images/img-2-aboutus.png";
import box3img from "../Images/img-3-aboutus.png";
import box4img from "../Images/img-4-aboutus.png";
import etsround from "../Images/etsround.png";
import { Container, useMediaQuery } from "@mui/material";
import { Carousel } from "react-responsive-carousel";

const HomeAboutUs = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const aboutUsData = [
    {
      image: etsround,
      content: `EduCampus Technologies is a premier IT training company dedicated
      to empowering individuals with the skills and knowledge required
      to excel in the technology-driven world. Our journey is marked by
      a commitment to quality education, innovative training methods,
      and a vision to shape the future of IT professionals.`,
      title: "Educampus Technologies",
    },
    {
      image: box1img,
      content:
        "Our story began in 2006 with the establishment of Bala's Placement and Computer Education. Over the years, we have trained 2100 students, laying a strong foundation in the field of IT training.",
      title: "Formation of Bala's Placement and Computer Education",
    },
    {
      image: box2img,
      content:
        "In 2017, EduCampus Technologies was officially formed, continuing our mission to provide top-notch IT education. This year marked a significant milestone with 400 students successfully trained under our new banner.",
      title: "Birth of EduCampus Technologies",
    },
    {
      image: box3img,
      content:
        "We launched Velaivendum.com in 2023, a platform designed to bridge the gap between job seekers and employers. This initiative has expanded our reach and enhanced our ability to support students in their career aspirations.",
      title: "Launch of Velaivendum.com",
    },
    {
      image: box4img,
      content:
        "Looking ahead, we have ambitious plans for 2024, with a goal to train 3000 students. Additionally, we are excited to launch the Velaivendum Job Seeker mobile app in June 2024, further enhancing our support for job seekers.",
      title: "Expanding Our Impact",
    },
  ];
  return (
    <div className="backgroundimg">
      <h2
        style={{
          textAlign: "center",
          margin: "20px 0px",
          flexGrow: "1",
          color: "#164C9C",
          font: "Inter",
        }}
      >
        About Us
      </h2>
      <br />
      <Container>
        <div
          className="container"
          style={{
            display: isMobile ? "flex" : "",
            flexDirection: isMobile ? "column" : "",
          }}
        >
          <div className=" box1">
            <div className="box">
              <div className="numberbox-img">
                <img src={box1img} alt="" />
              </div>
              <div>
                <h3 style={{ textAlign: "center", font: "Inter" }}>
                  Formation of Bala's Placement and Computer Education
                </h3>
              </div>
            </div>
            <div className="box">
              <div className="numberbox-img">
                <img src={box2img} alt="" />
              </div>
              <div>
                <h3 style={{ textAlign: "center", font: "Inter" }}>
                  Birth of EduCampus Technologies
                </h3>
              </div>
            </div>
          </div>
          <div
            className="box box2"
            style={{
              borderStyle: "dashed",
              borderColor: "blue",
              marginTop: isMobile ? "10px" : "",
            }}
          >
            <Carousel
              showArrows={false}
              autoPlay={true}
              interval={3000}
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              width={isMobile ? 300 : 368}
            >
              {aboutUsData.map((element) => {
                return (
                  <>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div className="numberCenterbox-img">
                          <img
                            style={{ width: "30%" }}
                            src={element.image}
                            alt=""
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h3 style={{ textAlign: "center", font: "Inter" }}>
                            {element.title}
                          </h3>
                          <p
                            className="text-container"
                            style={{
                              wordBreak: "keep-all",
                              color: "#4F4F4F",
                              fontSize: "18px",
                              textAlign: "center",
                              padding: "0px 20px ",
                            }}
                          >
                            {element.content}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Carousel>
          </div>
          <div className=" box3" style={{ marginTop: isMobile ? "10px" : "" }}>
            <div className="box">
              <div className="numberbox-img">
                <img src={box3img} alt="" />
              </div>
              <div>
                <h3 style={{ textAlign: "center", font: "Inter" }}>
                  Launch of Velaivendum.com
                </h3>
              </div>
              <span style={{ visibility: "hidden", font: "Inter" }}>
                Placement and Computer Education
              </span>
            </div>
            <div className="box">
              <div className="numberbox-img">
                <img src={box4img} alt="" />
              </div>
              <div>
                <h3 style={{ textAlign: "center", font: "Inter" }}>
                  Expanding Our Impact
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default HomeAboutUs;
