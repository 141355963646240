import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
// import courseImage from "../Images/card img.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import useSupabase from "../utils/useSupabase";
import dayjs from "dayjs";
import { Autocomplete, TextField } from "@mui/material";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "./RoudingData";
import SortIcon from "@mui/icons-material/Sort";

function WorkShopPage() {
  const [workshop, setWorkshop] = useState();
  const supabase = useSupabase();
  const navigate = useNavigate();
  const [filteredCourse, setFilteredCourse] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  useEffect(
    () => {
      fetchWorkshop();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchWorkshop = async () => {
    const { data } = await supabase
      .from("workshop")
      .select()
      .eq("isActive", true)
      .limit(4);
    setWorkshop(data);
    setFilteredCourse(data);
  };

  const handleClick = (index, data) => {
    setSelectedIndex(index);
    console.log("index, data:", index, data);
    if (selectedIndex === 0 && data === "All") {
      setWorkshop(filteredCourse);
    }
    if (data && data !== "All") {
      const filtered = filteredCourse.filter(
        (courseItem) => courseItem.workshopName === data
      );
      console.log("filtered", filtered);
      setWorkshop([...filtered]);
    } else {
      setWorkshop(filteredCourse);
    }
  };

  const options = [
    { label: "Most Recent", value: "mostRecent" },
    { label: "Duration", value: "duration" },
    { label: "Most Enrolled", value: "mostEnrolled" },
  ];

  return (
    <div>
      <Header />
      <div style={isMobile ? { padding: "10px" } : { padding: "25px" }}>
        <h1>Explore Workshops</h1>
        <text style={{ fontSize: "20px", fontWeight: 550 }}>
          Enhance your job-relevant skills with our extensive range of
          Workshops.
        </text>
        <Grid container sx={{ mt: 4 }}>
          <Grid md={3} xs={12} sx={{ pr: 3 }}>
            {/* <Autocomplete
              sx={{ mt: 2 }}
              size="small"
              options={options}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  {...params}
                  label="Sort By"
                  variant="outlined"
                />
              )}
            /> */}
            <div className="MobileViewFilter" onClick={toggleOverlay}>
              <h3>Filter</h3> <SortIcon sx={{ mt: 2.8, pl: 1 }} />
            </div>
            <div
              className="overlay-container"
              style={{ display: isOverlayVisible ? "block" : "none" }}
            >
              <div
                style={{ position: "sticky", top: "0" }}
                className="overlay-content"
              >
                <h3>Categories</h3>
                <Divider />
                <List>
                  {[{ workshopName: "All" }, ...filteredCourse].map(
                    (data, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: "rgba(57, 181, 74, 0.8)",
                            color:
                              selectedIndex === index ? "white" : "inherit",
                          },
                          backgroundColor:
                            selectedIndex === index ? "#39b54a" : "inherit",
                          color: selectedIndex === index ? "white" : "inherit",
                          my: 2,
                        }}
                        onClick={() => {
                          handleClick(index, data.workshopName);
                          toggleOverlay();
                        }}
                      >
                        <ListItemText
                          primary={data.workshopName}
                          primaryTypographyProps={{
                            sx: { fontWeight: 600 },
                          }}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              </div>
            </div>
            <div className="MobileViewCategories">
              <h3>Categories</h3>
              <Divider />
              <List>
                {[{ workshopName: "All" }, ...filteredCourse].map(
                  (data, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        cursor: "pointer",
                        borderRadius: "10px",
                        "&:hover": {
                          backgroundColor: "rgba(57, 181, 74, 0.8)",
                          color: selectedIndex === index ? "white" : "inherit",
                        },
                        backgroundColor:
                          selectedIndex === index ? "#39b54a" : "inherit",
                        color: selectedIndex === index ? "white" : "inherit",
                        my: 2,
                      }}
                      onClick={() => handleClick(index, data.workshopName)}
                    >
                      <ListItemText
                        primary={data.workshopName}
                        primaryTypographyProps={{
                          sx: { fontWeight: 600 },
                        }}
                      />
                    </ListItem>
                  )
                )}
              </List>
            </div>
          </Grid>
          <Grid container xs={12} md={9}>
            {workshop &&
              workshop?.map((data) => {
                return (
                  <Grid xs={12} sm={6} md={4} sx={{ p: 2 }}>
                    <Card
                      className="CourseCard"
                      style={{
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        cursor: "pointer",
                        borderRadius: "15px",
                      }}
                      onClick={() => {
                        navigate(`${ROUTINGDATA.WORKSHOPDETAIL}?id=${data.id}`);
                      }}
                    >
                      <img
                        src={data.imageUrl}
                        alt="courseImage"
                        style={{ height: "100%", width: "100%" }}
                      />
                      <div
                        className="courseCard"
                        style={{
                          padding: "1rem",
                          color: "#505050",
                          fontWeight: 600,
                        }}
                      >
                        <p
                          style={{
                            color: "#303030",
                            fontSize: "20px",
                            fontWeight: 600,
                            margin: 0,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "270px",
                          }}
                        >
                          {data.workshopName}
                        </p>
                        <div style={{ display: "flex", margin: "-7px 0px" }}>
                          <AccessTimeIcon sx={{ mt: 1.8, pr: 0.4 }} />
                          <p>
                            {dayjs(data.workshopTime).format("DD")}{" "}
                            {data.workshopDay},{" "}
                            {dayjs(data.workshopTime).format("hh:mm a")}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "-1.5rem",
                            marginBottom: "-1.4rem",
                          }}
                        >
                          <SignalCellularAltIcon sx={{ mt: 1.8, pr: 0.4 }} />
                          <p>{data.label} Level</p>
                        </div>
                        <p> By {data.trainer}</p>
                        <Divider sx={{ border: "1px solid gray" }} />
                        <br />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "-0.5rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <del style={{ marginTop: "0.1rem" }}>
                              ₹{data.realfees}
                            </del>
                            <p
                              style={{
                                margin: 0,
                                paddingLeft: "10px",
                                color: "black",
                                fontSize: "18px",
                              }}
                            >
                              ₹{data.fees}
                            </p>
                          </div>
                          <Button
                            variant="contained"
                            sx={{
                              // mt: "-0.6rem",
                              pt: "4px",
                              backgroundColor: "#39b54a",
                              textTransform: "capitalize",
                              fontWeight: 550,
                              color: "white",
                              borderColor: "#39b54a",
                              borderRadius: "25px",
                              "&:hover": {
                                textTransform: "capitalize",
                                color: "white",
                                backgroundColor: "#39b54a",
                                borderColor: "#39b54a",
                                fontWeight: 550,
                                borderRadius: "25px",
                              },
                            }}
                          >
                            Know More
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}

export default WorkShopPage;
