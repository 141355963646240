import React from "react";
import useSupabase from "../utils/useSupabase";
import { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ExploreCourse from "./ExploreCourse";
import ExploreWorkshops from "./ExploreWorkshops";
import Rating from "@mui/material/Rating";
import Chip from "@mui/material/Chip";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Grid,
  Button,
  useMediaQuery,
  Modal,
  Box,
  IconButton,
  Card,
  InputLabel,
  TextField,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContent,
  Tooltip,
} from "@mui/material";
import Image1 from "../Images/Illustration (1).png";
import Image2 from "../Images/OBJECTS.png";
import Image3 from "../Images/Illustration.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import enroll from "../Images/enroll.jpg";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "./RoudingData";

const WorkshopDetail = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const supabase = useSupabase();
  const [workshopdetail, setWorkshopdetail] = useState([]);
  const [searchParams] = useSearchParams();
  const workshopId = searchParams.get("id");
  const [emailError, setEmailError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showRemainingSkills, setShowRemainingSkills] = useState(false);

  useEffect(
    () => {
      fetchWorkshopDetail();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workshopId]
  );

  const [datas, setDatas] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = localStorage.getItem("userId");
    if (storedData) {
      setDatas(storedData);
    }
  }, []);

  const fetchWorkshopDetail = async () => {
    let { data, error } = await supabase.rpc("workshop_detail", {
      workshopid: parseInt(workshopId),
    });
    if (error) {
      console.error(error);
    } else {
      setWorkshopdetail(data);
    }
  };
  const [openPopup, setOpenPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    whatsapp: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "email") {
      validateEmail(value);
    }
  };
  const validateEmail = (email) => {
    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(email));
  };
  useEffect(() => {
    validateForm();
  }, [formData, emailError]); // eslint-disable-line react-hooks/exhaustive-deps
  const validateForm = () => {
    const { name, email, whatsapp } = formData;
    setIsFormValid(
      name.trim() !== "" &&
        email.trim() !== "" &&
        whatsapp.trim() !== "" &&
        !emailError
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError) {
      return;
    }
    setLoading(true); // Start loading
    try {
      // Insert data into the 'enroll' table
      const { data, error } = await supabase.from("enrollWorkshop").insert([
        {
          name: formData.name,
          email: formData.email,
          whatsappNo: formData.whatsapp,
          workshopId: workshopId,
        },
      ]);
      if (error) {
        throw error;
      }
      console.log("Data inserted successfully:", data);
      const emailContent = `
      <h3>Course Enrollment</h3>
      <p><strong>Name:</strong> ${formData.name}</p>
      <p><strong>Workshop ID:</strong> ${workshopId}</p>
      <p><strong>Email:</strong> ${formData.email}</p>
      <p><strong>Phone No.:</strong> ${formData.whatsapp}</p>
    `;

      const response = await fetch(
        "https://usgs-mail-server.onrender.com/usgs-services/sendmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            html: emailContent,
            to: "sachin.s@educampustech.in",
            subject: "Workshop Enrollment",
          }),
        }
      );

      if (response.ok) {
        setOpenSnackbar(true);
      } else {
        console.error("Failed to send email");
      }

      handleClosePopup();
    } catch (error) {
      console.error("Error inserting data:", error.message);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleOpenPopup = () => setOpenPopup(true);
  const handleClosePopup = () => {
    setFormData({
      name: "",
      email: "",
      whatsapp: "",
    });
    setOpenPopup(false);
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const handleSkillsClick = () => {
    setShowRemainingSkills(!showRemainingSkills);
  };
  return (
    <div>
      <Header />
      {workshopdetail.map((item) => {
        const backgroundImageStyle = {
          backgroundImage: `url(${item.imageUrl})`,
        };
        const skills = item.skills.split(",").map((skill) => skill.trim());
        const displayedSkillsCount = isMobile ? 3 : 5;
        const remainingSkills = skills.slice(displayedSkillsCount);
        return (
          <>
            {" "}
            <div
              className="detailPageImage1"
              key={item.detailId}
              style={backgroundImageStyle}
            >
              <Grid container>
                <Grid md={6} sx={{ p: 5 }}>
                  <h1>{item.workshopName}</h1>
                  <h4>By {item.trainer} </h4>
                  <Rating
                    name="half-rating-read"
                    defaultValue={4.5}
                    precision={0.5}
                    readOnly
                  />
                  <p style={{ textAlign: "justify" }}>{item.workshopDesc}</p>
                  {skills.slice(0, displayedSkillsCount).map((skill, index) => (
                    <Chip
                      key={index}
                      sx={{ color: "white", margin: "5px" }}
                      label={skill}
                      variant="outlined"
                    />
                  ))}
                  {skills.length > displayedSkillsCount && (
                    <>
                      {isMobile ? (
                        <>
                          <Chip
                            sx={{ color: "white", margin: "5px" }}
                            label={
                              showRemainingSkills
                                ? "Show Less"
                                : `+${remainingSkills.length} skills`
                            }
                            variant="outlined"
                            onClick={handleSkillsClick}
                          />
                          {showRemainingSkills && (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", p: 1 }}
                            >
                              {remainingSkills.map((skill, index) => (
                                <Chip
                                  key={index}
                                  sx={{ color: "white", margin: "5px" }}
                                  label={skill}
                                  variant="outlined"
                                />
                              ))}
                            </Box>
                          )}
                        </>
                      ) : (
                        <Tooltip
                          title={
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", p: 1 }}
                            >
                              {remainingSkills.map((skill, index) => (
                                <Chip
                                  key={index}
                                  sx={{ color: "white", margin: "5px" }}
                                  label={skill}
                                  variant="outlined"
                                />
                              ))}
                            </Box>
                          }
                        >
                          <Chip
                            sx={{ color: "white", margin: "5px" }}
                            label={`+${remainingSkills.length} skills`}
                            variant="outlined"
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <del
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        margin: "0 10px 0 0",
                        color: "black",
                      }}
                    >
                      ₹{item.realfees}
                    </del>
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        margin: "0",
                      }}
                    >
                      ₹{item.fees}
                    </p>
                  </div>
                  <Button
                    variant="contained"
                    sx={{
                      mt: "1rem",
                      backgroundColor: "#39b54a",
                      textTransform: "capitalize",
                      fontWeight: 550,
                      color: "white",
                      borderColor: "#39b54a",
                      borderRadius: "25px",
                      "&:hover": {
                        textTransform: "capitalize",
                        color: "white",
                        backgroundColor: "#39b54a",
                        borderColor: "#39b54a",
                        fontWeight: 550,
                        borderRadius: "25px",
                      },
                    }}
                    //onClick={handleOpenPopup}
                    onClick={datas ? handleOpenPopup : () => navigate(ROUTINGDATA.LOGIN)}
                  >
                    Enroll now
                  </Button>
                </Grid>
              </Grid>
            </div>
          </>
        );
      })}
      <Modal
        open={openPopup}
        onClose={handleClosePopup}
        aria-labelledby="form-modal-title"
        aria-describedby="form-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "90%" : 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <IconButton onClick={handleClosePopup} sx={{ float: "left" }}>
            <ArrowBackIcon />
          </IconButton>
          <Card sx={{ padding: 2, mt: isMobile ? 2 : 5 }}>
            <img
              src={enroll}
              alt="form-header"
              style={{
                width: "100%",
                height: isMobile ? "10vh" : "20vh",
                borderRadius: "8px",
              }}
            />
            <form onSubmit={handleSubmit}>
              <InputLabel sx={{ marginTop: 2 }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  Name
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Enter your name"
                variant="outlined"
                required
                name="name"
                value={formData.name}
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, "");
                }}
              />
              <InputLabel sx={{ marginTop: 2 }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  Enter your Email id
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Enter your mail id"
                variant="outlined"
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={emailError}
                helperText={
                  emailError ? "Please enter a valid email address" : ""
                }
              />
              <InputLabel sx={{ marginTop: 2 }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  WhatsApp number
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Enter your current number"
                variant="outlined"
                required
                name="whatsapp"
                value={formData.whatsapp}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target.value.replace(/\D/g, "");
                  const trimmedInput = input.slice(0, 10);
                  e.target.value = trimmedInput;
                  handleChange(e);
                }}
              />
              <Button
                variant="contained"
                color="success"
                type="submit"
                sx={{ mt: 3 }}
                disabled={!isFormValid}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          </Card>
        </Box>
      </Modal>
      <Dialog open={openSnackbar} onClose={handleSnackbarClose}>
        <DialogContent
          sx={{
            textAlign: "center",
            padding: "40px 20px",
            color: "#fff",
            borderRadius: "8px",
          }}
        >
          <CheckCircleOutlineIcon sx={{ fontSize: "70px", color: "#4CAF50" }} />
          <DialogTitle
            sx={{ fontSize: "24px", fontWeight: "bold", color: "black" }}
          >
            Thank You!
          </DialogTitle>
          <Typography sx={{ marginTop: "10px", color: "black" }}>
            Thank you for submitting your details and enroll in our workshop!
            Our team will be in touch you shortly.
          </Typography>
          <Button
            onClick={handleSnackbarClose}
            variant="contained"
            sx={{
              marginTop: "20px",
              backgroundColor: "#4CAF50",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#45A049",
              },
            }}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
      <br />
      <div style={{ backgroundColor: "#F1F6FF" }}>
        <h2 style={{ textAlign: "center", paddingTop: "5px" }}>
          What is Included?
        </h2>
        <Grid
          container
          sx={{
            color: "#2E2E2E",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            paddingInline: "7rem",
            paddingBottom: "30px",
            justifyContent: "center",
          }}
        >
          <Grid
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: isMobile ? "30PX" : "0px",
            }}
          >
            <div
              style={{
                border: "1px solid #084FC7",
                borderRadius: "5px",
                padding: "0.5rem 4rem",
              }}
            >
              <img
                src={Image1}
                alt="Image1"
                style={{ height: "150px", width: "150px" }}
              />
              <p style={{ margin: "4px" }}>Live Project</p>
            </div>
          </Grid>
          <Grid
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: isMobile ? "30PX" : "0px",
            }}
          >
            <div
              style={{
                border: "1px solid #084FC7",
                borderRadius: "5px",
                padding: "0.5rem 4rem",
              }}
            >
              <img
                src={Image2}
                alt="Image2"
                style={{ height: "150px", width: "150px" }}
              />
              <p style={{ margin: "4px" }}>Certification</p>
            </div>
          </Grid>
          <Grid
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: isMobile ? "30PX" : "0px",
            }}
          >
            <div
              style={{
                border: "1px solid #084FC7",
                borderRadius: "5px",
                padding: "0.5rem 4rem",
              }}
            >
              <img
                src={Image3}
                alt="Image3"
                style={{ height: "150px", width: "150px" }}
              />
              <p style={{ margin: "4px" }}>Job Guidance</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingInline: isMobile ? "2rem" : "6rem" }}>
        <h2 style={{ textAlign: "center" }}>
          Topics that Covered in this Course
        </h2>

        {workshopdetail.map((item) => {
          return (
            <div key={item.id}>
              {[...Array(6)].map(
                (
                  _,
                  index // Assuming there are always 6 topics and descriptions
                ) => (
                  <Accordion
                    key={`${item.id}-topic-${index + 1}`}
                    sx={{
                      mb: 1,
                      borderRadius: "5px",
                      backgroundColor: "#F6FAFF",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${item.id}-content`}
                      id={`panel${item.id}-header`}
                      sx={{
                        color: "#444BE2",
                        border: "1px solid #97BEFF",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography>{item[`topic${index + 1}`]}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        color: "#444BE2",
                        border: "1px solid #97BEFF",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography>{item[`desc${index + 1}`]}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )
              )}
            </div>
          );
        })}
      </div>
      <br />
      <ExploreCourse />
      <ExploreWorkshops />
      <br />
      <div
        style={{
          paddingInline: isMobile ? "2rem" : "6rem",
        }}
      >
        <h2 style={{ textAlign: "center", marginTop: "-10px" }}>FAQ</h2>
        {workshopdetail.map((item) => {
          return (
            <div key={item.id}>
              {[...Array(6)].map((_, index) => (
                <Accordion
                  key={`${item.id}-topic-${index + 1}`}
                  sx={{
                    mb: 1,
                    borderRadius: "5px",
                    backgroundColor: "#EEEEEE",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${item.id}-content`}
                    id={`panel${item.id}-header`}
                    sx={{
                      color: "#2C2C2C",
                      border: "1px solid #C9C9C9",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography>{item[`faq${index + 1}`]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      color: "#2C2C2C",
                      border: "1px solid #C9C9C9",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography>{item[`answer${index + 1}`]}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          );
        })}
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default WorkshopDetail;
