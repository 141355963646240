import "./App.css";
import { ROUTINGDATA } from "./Components/RoudingData";
import { HashRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Components/HomePage";
import DetailsPage from "./Components/DetailsPage";
import PrivacyPolicy from "./PrivacyPolicy";
import Login from "./Components/Login";
import CoursePage from "./Components/CoursePage";
import WorkShopPage from "./Components/WorkShopPage";
import TermsConditions from "./Components/TermsConditions";
import SignUp from "./Components/SignUp";
import ContactUs from "./Components/ContactUs";
import CategoryPage from "./Components/CategoryPage";
import WorkshopDetail from "./Components/WorkshopDetail";
import AboutUs from "./Components/AboutUs";
import ForgetPassword from "./Components/Forgetpassword";
import ChangePassword from "./Components/ChangePassword";
import ConfirmMail from "./Components/ConfirmMail";
import PricePolicy from "./Components/PricePolicy";
import RefundPolicy from "./Components/RefundPolicy";
import Blog from "./Components/Blog";
import BlogDetails from "./Components/BlogDetails";
import Profile from "./Components/Profile";

function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path={ROUTINGDATA.HOME} element={<HomePage />} />
          <Route path={ROUTINGDATA.DETAILSPAGE} element={<DetailsPage />} />
          <Route path={ROUTINGDATA.CONTACT} element={<ContactUs />} />
          <Route path={ROUTINGDATA.PRIVACY} element={<PrivacyPolicy />} />
          <Route path={ROUTINGDATA.LOGIN} element={<Login />} />
          <Route path={ROUTINGDATA.SIGNUP} element={<SignUp />} />
          <Route path={ROUTINGDATA.COURSE} element={<CoursePage />} />
          <Route path={ROUTINGDATA.WORKSHOP} element={<WorkShopPage />} />
          <Route path={ROUTINGDATA.ABOUTUS} element={<AboutUs />} />
          <Route path={ROUTINGDATA.PROFILE} element={<Profile />} />
          <Route
            path={ROUTINGDATA.TERMSCONDITIONS}
            element={<TermsConditions />}
          />
          <Route path={ROUTINGDATA.PRICEPOLICY} element={<PricePolicy />} />
          <Route path={ROUTINGDATA.REFUNDPOLICY} element={<RefundPolicy />} />
          <Route path={ROUTINGDATA.CATEGORYPAGE} element={<CategoryPage />} />
          <Route
            path={ROUTINGDATA.WORKSHOPDETAIL}
            element={<WorkshopDetail />}
          />
          <Route
            path={ROUTINGDATA.ForgetPassword}
            element={<ForgetPassword />}
          />
          <Route
            path={ROUTINGDATA.ChangePassword}
            element={<ChangePassword />}
          />
          <Route path={ROUTINGDATA.ConfirmMail} element={<ConfirmMail />} />
          <Route path={ROUTINGDATA.BLOG} element={<Blog />} />
          <Route
            path={`${ROUTINGDATA.BLOGDETAILS}/:id`}
            element={<BlogDetails />}
          />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
