import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import etsgirl from "../Images/etsgirl.png";
import industryreadyicon from "../Images/industryreadyicon.png";
import flexiblettymicon from "../Images/flexibletymicon.png";
import industrialexperticon from "../Images/industrialexperticon.png";
import certificateicon from "../Images/certificateicon.png";

const ChooseUs = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // width: "100%",
        //height: "650px",
        backgroundColor: "BlanchedAlmond",
        padding: "20px",
      }}
    >
      <Typography
        variant="h2"
        style={{
          padding: "20px",
          textAlign: "center",
          font: "Inter",
          fontSize: "26px",
          fontWeight: "700",
          lineHeight: "36.74px",
        }}
      >
        Why Choose Us ?
      </Typography>
      <br />
      <Grid container spacing={2} style={{ width: "100%" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          style={{ textAlign: isMobile ? "center" : "left" }}
        >
          <img
            alt="etsgirl"
            src={etsgirl}
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                height: "231px",
                borderRadius: "6px",
                backgroundColor: "white",
                boxShadow: "0px 0px 0px 2px rgba(22, 76, 156, 1)",
                padding: "15px",
              }}
            >
              <img
                alt="industry"
                src={industryreadyicon}
                style={{ padding: "15px" }}
              />
              <Typography
                style={{
                  font: "Inter",
                  fontWeight: "700",
                  fontSize: "19px",
                  lineHeight: "26.85px",
                  color: "rgba(21, 50, 94, 1)",
                  paddingLeft: "20px",
                }}
              >
                Industry Ready Courses
              </Typography>
              <Typography
                style={{
                  font: "Inter",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "17.08px",
                  color: "rgba(89, 114, 156, 1)",
                  paddingLeft: "20px",
                  paddingTop: "7px",
                }}
              >
                Explore a wide range of courses spanning various subjects,
                industries, and skill levels. From technology and business to
                arts and personal development, there's something for everyone.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                height: "231px",
                borderRadius: "6px",
                backgroundColor: "white",
                boxShadow: "0px 0px 0px 2px rgba(22, 76, 156, 1)",
                padding: "15px",
              }}
            >
              <img
                alt="flexible"
                src={flexiblettymicon}
                style={{ padding: "10px" }}
              />
              <Typography
                style={{
                  font: "Inter",
                  fontWeight: "700",
                  fontSize: "19px",
                  lineHeight: "26.85px",
                  color: "rgba(21, 50, 94, 1)",
                  paddingLeft: "20px",
                  marginTop: "-5px",
                }}
              >
                Flexible Timing
              </Typography>
              <Typography
                style={{
                  font: "Inter",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "17.08px",
                  color: "rgba(89, 114, 156, 1)",
                  paddingLeft: "20px",
                  paddingTop: "7px",
                }}
              >
                With our flexible learning options, you can study at your own
                pace, on your own schedule. Whether you prefer to learn in short
                bursts or dive deep into a subject, our platform accommodates
                your needs.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                height: "231px",
                borderRadius: "6px",
                backgroundColor: "white",
                boxShadow: "0px 0px 0px 2px rgba(22, 76, 156, 1)",
                padding: "15px",
              }}
            >
              <img
                alt="industrial"
                src={industrialexperticon}
                style={{ padding: "15px" }}
              />
              <Typography
                style={{
                  font: "Inter",
                  fontWeight: "700",
                  fontSize: "19px",
                  lineHeight: "26.85px",
                  color: "rgba(21, 50, 94, 1)",
                  paddingLeft: "20px",
                  marginTop: "-5px",
                }}
              >
                Industrial Experts
              </Typography>
              <Typography
                style={{
                  font: "Inter",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "17.08px",
                  color: "rgba(89, 114, 156, 1)",
                  paddingLeft: "20px",
                  paddingTop: "7px",
                }}
              >
                Learn from industry experts, seasoned professionals, and
                passionate educators who are dedicated to your success. Our
                instructors bring real-world experience and cutting-edge
                knowledge to the virtual classroom.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                height: "231px",
                borderRadius: "6px",
                backgroundColor: "white",
                boxShadow: "0px 0px 0px 2px rgba(22, 76, 156, 1)",
                padding: "15px",
              }}
            >
              <img
                alt="certificate"
                src={certificateicon}
                style={{ padding: "15px" }}
              />
              <Typography
                style={{
                  font: "Inter",
                  fontWeight: "700",
                  fontSize: "19px",
                  lineHeight: "26.85px",
                  color: "rgba(21, 50, 94, 1)",
                  paddingLeft: "20px",
                  marginTop: "-12px",
                }}
              >
                Certificate
              </Typography>
              <Typography
                style={{
                  font: "Inter",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "17.08px",
                  color: "rgba(89, 114, 156, 1)",
                  paddingLeft: "20px",
                  paddingTop: "7px",
                }}
              >
                Upon completion of your course, you'll receive a prestigious
                certificate. It shows your mastery of the subject and dedication
                to professional growth.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChooseUs;
