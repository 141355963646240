import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import HomeCoursePage from "./HomeCoursePage";

function CoursePage() {
  return (
    <div style={{ backgroundColor: "#f2f6ff" }}>
      <Header />
      <HomeCoursePage />
      <Footer />
    </div>
  );
}

export default CoursePage;
