import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Header from "./Header";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import profileimg from "../Images/profileavt.png";
import useSupabase from "../utils/useSupabase";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

const Sidebar = styled.div`
  width: 300px;
  height: 92.5vh;
  background-color: #39b54a;
  box-sizing: border-box;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  background-color: #fff;
  padding: 40px;
  box-sizing: border-box;
`;

const Card = styled.div`
  height: 490px;
  width: 458px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px #8bcb93;
  max-width: 400px;
  text-align: center;
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  color: #216f2c;
`;

const PopupContainer = styled.div`
  position: absolute;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: ${({ open }) => (open ? "block" : "none")};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
`;

const Profile = () => {
  const { id } = useParams();
  const supabase = useSupabase();
  const [popupOpen, setPopupOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [publicUrls, setpublicUrls] = useState("");
  const [profileData, setProfileData] = useState({
    name: "",
    number: "",
    email: "",
    imageUrl: "",
  });
  const [originalProfileData, setOriginalProfileData] = useState({});
  const [errors, setErrors] = useState({});
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchProfileData = async () => {
    const { data: sessionData, error: sessionError } =
      await supabase.auth.getSession();
    if (sessionError) {
      console.error("Error fetching session data:", sessionError);
      return;
    }

    const { data, error } = await supabase
      .from("userProfile")
      .select("*")
      .eq("userUID", sessionData.session.user.id)
      .single();
    if (error) {
      console.error("Error fetching profile data:", error);
    } else {
      setProfileData(data);
      setOriginalProfileData(data); // Save the original profile data
      const publicUrl = supabase.storage
        .from("userProfile")
        .getPublicUrl(data.imageUrl);
      setpublicUrls(publicUrl.data.publicUrl);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [id, supabase]);

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  const closeEditPopup = () => {
    setProfileData(originalProfileData); // Reset to original data
    setEditOpen(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z\s]+$/, "Name should only contain letters and spaces"),
    number: Yup.number()
      .required("Mobile number is required")
      .min(6000000000, "Invalid Mobile number")
      .max(9999999999, "Invalid Mobile number "),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid Email Id"
      )
      .email("Email is invalid"),
    // Optionally add validation for image file
  });

  const formik = useFormik({
    initialValues: profileData,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Upload image if it's updated
        if (values.image) {
          // Extract filename from imageUrl
          if(profileData.imageUrl){

          
          const oldImageKey = profileData.imageUrl.split("/").pop();

          // Delete old image from storage
          await supabase.storage
          .from("userProfile")
          .remove([oldImageKey]);
          }
          const { data: fileData, error: fileError } = await supabase.storage
            .from("userProfile")
            .upload(`${values.image.name}`, values.image);
          const imageUrl = `${fileData.path}`;
          if (fileError) {
            throw new Error("Error uploading image:", fileError.message);
          } else {
            const publicUrl = await supabase.storage
              .from("userProfile")
              .getPublicUrl(fileData.path);
            setpublicUrls(publicUrl.data.publicUrl);
          }
          const { error: updateError } = await supabase
            .from("userProfile")
            .update({
              name: values.name,
              number: values.number,
              email: values.email,
              imageUrl: imageUrl,
            })
            .eq("userUID", values.userUID);
          if (updateError) {
            throw new Error(
              "Error updating profile data:",
              updateError.message
            );
          }
        }
        else{
          const { error: updateError } = await supabase
          .from("userProfile")
          .update({
            name: values.name,
            number: values.number,
            email: values.email, 
          })
          .eq("userUID", values.userUID);
        if (updateError) {
          throw new Error(
            "Error updating profile data:",
            updateError.message
          );
        }
        }

        // Update profile data in Supabase

        // Fetch updated profile data from Supabase
        const { data: updatedData, error: fetchError } = await supabase
          .from("userProfile")
          .select("*")
          .eq("userUID", values.userUID)
          .single();

        if (fetchError) {
          throw new Error(
            "Error fetching updated profile data:",
            fetchError.message
          );
        }

        // Update state with the latest fetched data
        setProfileData(updatedData);
        setOriginalProfileData(updatedData); // Update the original data with the saved data
        setErrors({});
        closeEditPopup();
        handleClose();
        fetchProfileData();
      } catch (error) {
        console.error("Error during saveProfileData:", error.message);
        setErrors({ image: "Error during image upload or profile update" });
      }
    },
    enableReinitialize: true,
  });

  return (
    <div>
      <Header />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{ p: 4 }}
      >
        <form onSubmit={formik.handleSubmit} style={{ padding: "2rem" }}>
          <TextField
            variant="standard"
            label="Name"
            fullWidth
            {...formik.getFieldProps("name")}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Mobile Number"
            fullWidth
            variant="standard"
            {...formik.getFieldProps("number")}
            onInput={(e) => {
              const input = e.target.value.replace(/\D/g, "");
              const trimmedInput = input.slice(0, 10);
              e.target.value = trimmedInput;
            }}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
            sx={{ mb: 2 }}
          />
          <TextField
          disabled
            label="Email"
            fullWidth
            variant="standard"
            {...formik.getFieldProps("email")}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{ mb: 2 }}
          />
          <label>Profile Image:</label> <br></br>
          <input
            type="file"
            onChange={(e) => {
              formik.setFieldValue("image", e.currentTarget.files[0]);
            }}
          />
          {formik.errors.image && (
            <ErrorMessage>{formik.errors.image}</ErrorMessage>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "10px",
              paddingTop: "25px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              style={{
                width: "109px",
                height: "36px",
                padding: "8px 16px",
                backgroundColor: "#ccc",
                color: "#444444",
                border: "none",
                borderRadius: "40px",
                cursor: "pointer",
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                width: "109px",
                height: "36px",
                padding: "8px 16px",
                backgroundColor: "#39b54a",
                color: "white",
                border: "none",
                borderRadius: "40px",
                cursor: "pointer",
              }}
            >
              Save
            </Button>
          </div>
        </form>
      </Dialog>
      <ProfileContainer>
        <Sidebar>
          <p
            style={{
              fontWeight: "600",
              fontSize: "26px",
              textAlign: "center",
              cursor: "pointer",
              paddingTop: "50px",
              color: "white",
            }}
          >
            My Profile
          </p>
        </Sidebar>
        <MainContent>
          <Card>
            <IconContainer onMouseEnter={togglePopup}>
              <MoreVertIcon />
              <PopupContainer style={{ width: "100px" }} open={popupOpen}>
                <p
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={handleClickOpen}
                >
                  Edit
                </p>
                <p style={{ fontWeight: "bold" }}>Help</p>
              </PopupContainer>
            </IconContainer>
            <img
              style={{ height: "200px", width: "200px", borderRadius: "100%" }}
              src={profileData.imageUrl ? `${publicUrls}` : profileimg}
              alt="profileimg"
            />
            <p
              style={{ color: "#216F2C", fontSize: "30px", fontWeight: "600" }}
            >
              {profileData.name}
            </p>
            <InfoRow>
              <CallIcon />
              <span
                style={{
                  marginLeft: "10px",
                  fontSize: "26px",
                  fontWeight: "500",
                }}
              >
                {profileData.number}
              </span>
            </InfoRow>
            <InfoRow>
              <EmailIcon />
              <span
                style={{
                  marginLeft: "10px",
                  fontSize: "26px",
                  fontWeight: "500",
                }}
              >
                {profileData.email}
              </span>
            </InfoRow>
          </Card>
        </MainContent>
      </ProfileContainer>
      {editOpen && <Overlay />}
    </div>
  );
};

export default Profile;
