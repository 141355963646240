import React, { useState } from "react";
import { Fragment } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CallIcon from "@mui/icons-material/Call";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import signup from "../Images/signup.png";
import { Link } from "react-router-dom";
import { ROUTINGDATA } from "./RoudingData";
import useSupabase from "../utils/useSupabase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SignUp = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [showPassword, setShowPassword] = useState(false);
  const supabase = useSupabase();
  const [message, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const initialValues = {
    name: "",
    email: "",
    number: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid Email Id"
      )
      .required(" Email Id is required"),
      number: yup.number().required('Mobile number is required').min(6000000000, 'Invalid Mobile number')
      .max(9999999999, 'Invalid Mobile number '),
      password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // const email = values.email;
      // const password = values.password;
      const { data: signUpUser, error: signUpError } =
        await supabase.auth.signUp({
          email: values.email,
          password: values.password,
          options: {
            emailRedirectTo: `www.educampustech.com/#/ConfirmMail`,
            // data: {
            //   password: values.password,
            // },
          },
        });

      if (signUpError) {
        handleClickOpen();
        setMessage(signUpError.message);
      }

      const { data: userProfile } = await supabase
        .from("userProfile")
        .select()
        .eq("email", values.email);

      if (userProfile?.length === 0) {
        const { error: profileError } = await supabase
          .from("userProfile")
          .insert({
            userUID: signUpUser.user?.id,
            email: values.email,
            name: values.name,
            number: values.number,
            userType: "Student",
          });

        if (profileError) {
          handleClickOpen();
          setMessage("Unable to create profile");
          return;
        } else {
          handleClickOpen();
          setIsLogin(true);
          setMessage("User profile created successfully. Check your email");
          formik.resetForm();
        }
      } else {
        handleClickOpen();
        setMessage("User already exits");
      }
    },
  });

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          // height: isMobile ? "740px" : "740px",
          background: isMobile
            ? "none"
            : "linear-gradient(180deg, #EEFFF0 0%, #EAF2FF 100%)",
        }}
      >
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent sx={{ width: "200px", p: 4, textAlign: "center" }}>
            {isLogin ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CheckCircleOutlineIcon
                  sx={{ fontSize: "70px", color: "#4CAF50" }}
                />
              </div>
            ) : null}
            <DialogContentText
              sx={{ fontSize: "20px", fontWeight: "bold" }}
              id="alert-dialog-slide-description"
            >
              {message}
            </DialogContentText>
            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "20px",
                  display: "block",
                  background: "#39B54A",
                  borderRadius: "40px",
                  paddingRight: "22px",
                  paddingLeft: "22px",
                  color: "white",
                  textTransform: "capitalize",
                  "&:hover": {
                    textTransform: "capitalize",
                    color: "#333",
                    background: "#39B54A",
                    borderColor: "#39b54a",
                    fontWeight: 550,
                    borderRadius: "25px",
                  },
                }}
                onClick={handleClose}
              >
                Ok
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <br />
        <br />
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "700px",
          }}
        >
          {!isMobile && (
            <div
              style={{
                flex: 1,
                marginLeft: "40px",
              }}
            >
              <Link to={ROUTINGDATA.HOME}>
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <br />
              <br />
              <br />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "30px",
                  fontWeight: "700",
                  lineHeight: "45px",
                  textAlign: "center",
                  background:
                    "linear-gradient(90.02deg, #39B54A 30.02%, #084FC7 58.52%, #0D4FBE 99.98%)",

                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                }}
              >
                WELCOME LEARNERS
              </Typography>
              <img alt="signup" src={signup} style={{ paddingLeft: "50px" }} />
            </div>
          )}
          <div
            style={{
              flex: 1,
              marginRight: "40px",
              display: "flex",
              flexDirection: isMobile ? "column" : "none",
            }}
          >
            <Card
              sx={{
                width: isMobile ? "98%" : "680px",
                height: isMobile ? "auto" : "630px",
                top: isMobile ? "0" : "60px",
                //left: "700px",
                gap: "0px",
                borderRadius: "20px ",
                opacity: "0px",
                background: "#FFFFFF",
                padding: isMobile ? "20px" : "0",
                boxShadow: isMobile ? "none" : "default",
              }}
            >
              {isMobile && (
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "30px",
                    fontWeight: "700",
                    lineHeight: "45px",
                    textAlign: "center",
                    background:
                      "linear-gradient(90.02deg, #39B54A 30.02%, #084FC7 58.52%, #0D4FBE 99.98%)",

                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    paddingTop: "20px",
                  }}
                >
                  WELCOME LEARNERS
                </Typography>
              )}
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "36px",
                  paddingTop: "20px",
                }}
              >
                Sign up
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <InputLabel
                  sx={{
                    paddingTop: "20px",
                    paddingLeft: isMobile ? "0px" : "69px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "500PX",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "19.36px",
                    }}
                  >
                    Enter your Full Name
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <TextField
                  size="small"
                  name="name"
                  placeholder="Enter your full name"
                  sx={{
                    paddingLeft: isMobile ? "0" : "69px",
                    width: isMobile ? "100%" : "554px",
                    borderRadius: "20px",
                    fontFamily: "Inter",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutlineOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(
                      /[^a-zA-Z\s]/g, 
                      ""
                    );
                  }}
                />
                {formik.touched.name && formik.errors.name && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      paddingLeft: isMobile ? "0px" : "69px",
                    }}
                  >
                    {formik.errors.name}
                  </Typography>
                )}
                <InputLabel
                  sx={{
                    paddingTop: "15px",
                    paddingLeft: isMobile ? "0px" : "69px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "500PX",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "19.36px",
                    }}
                  >
                    Enter your Email id
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <TextField
                  size="small"
                  name="email"
                  placeholder="Enter your mail id"
                  sx={{
                    paddingLeft: isMobile ? "0px" : "69px",
                    width: isMobile ? "100%" : "554px",
                    borderRadius: "20px",
                    fontFamily: "Inter",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
                {formik.touched.email && formik.errors.email && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      paddingLeft: isMobile ? "0px" : "69px",
                    }}
                  >
                    {formik.errors.email}
                  </Typography>
                )}
                <InputLabel
                  sx={{
                    paddingTop: "15px",
                    paddingLeft: isMobile ? "0px" : "69px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "500PX",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "19.36px",
                    }}
                  >
                    Phone Number
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <Box
                  sx={{
                    display: "flex",
                    paddingLeft: isMobile ? "0px" : "69px",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    name="number"
                    placeholder="Enter your phone no."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CallIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: isMobile ? "100%" : "554px",
                      borderRadius: "20px",
                      fontFamily: "Inter",
                    }}
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.number && Boolean(formik.errors.number)
                    }
                    onInput={(e) => {
                      const input = e.target.value.replace(/\D/g, "");
                      const trimmedInput = input.slice(0, 10);
                      e.target.value = trimmedInput;
                    }}
                  />
                </Box>
                {formik.touched.number && formik.errors.number && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      paddingLeft: isMobile ? "0px" : "69px",
                    }}
                  >
                    {formik.errors.number}
                  </Typography>
                )}
                <InputLabel
                  sx={{
                    paddingTop: "15px",
                    paddingLeft: isMobile ? "0px" : "69px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "500PX",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "19.36px",
                    }}
                  >
                    Enter your password
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>

                <TextField
                  size="small"
                  name="password"
                  placeholder="Enter your password"
                  type={showPassword ? "text" : "password"}
                  sx={{
                    paddingLeft: isMobile ? "0px" : "69px",
                    width: isMobile ? "100%" : "554px",
                    borderRadius: "20px",
                    fontFamily: "Inter",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                />
                {formik.touched.password && formik.errors.password && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      paddingLeft: isMobile ? "0px" : "69px",
                    }}
                  >
                    {formik.errors.password}
                  </Typography>
                )}
                <br />
                <Button
                  type="submit"
                  sx={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "20px",
                    display: "block",
                    background: "#39B54A",
                    borderRadius: "40px",
                    paddingRight: "22px",
                    paddingLeft: "22px",
                    color: "white",
                    textTransform: "capitalize",
                    "&:hover": {
                      textTransform: "capitalize",
                      color: "#333",
                      background: "#39B54A",
                      borderColor: "#39b54a",
                      fontWeight: 550,
                      borderRadius: "15px",
                    },
                  }}
                >
                  Sign up
                </Button>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "16.94px",
                    textAlign: "center",
                    paddingTop: "7px",
                  }}
                >
                  Already have an account ?
                  <Link to={ROUTINGDATA.LOGIN} style={{ color: " #006EBE" }}>
                    LOGIN
                  </Link>
                </Typography>
                <br />
                <br />
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "16.94px",
                    textAlign: "center",
                  }}
                >
                  By continuing, you agree to Educampus
                  <Link
                    to={ROUTINGDATA.TERMSCONDITIONS}
                    style={{ color: " #006EBE" }}
                  >
                    Terms Of Use{" "}
                  </Link>{" "}
                  and{" "}
                  <Link to={ROUTINGDATA.PRIVACY} style={{ color: " #006EBE" }}>
                    Privacy Policy
                  </Link>
                </Typography>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignUp;
